import React from 'react';
import Title from "../../../components/UI/Title/Title";
import Shape from "../../../components/UI/Shap/Shape";
import Staging from "../../../components/NotFound/Staging/Staging";
import bg1 from "../../../img/slider2.webp";

const CultPhotos = () => {
    return (
        <>
            <Shape
                height={'40vh'}
                backgroundColor={'linear-gradient(to right, rgba(15,66,139,0.6), rgba(15,66,139,0.6))'}
                backgroundImage={bg1}
                backgroundSize={'cover'}
                backgroundPosition={'center center'}
                backgroundAttachment={'fixed'}
            >
                <Title
                    title={'Saint-Quentin Basketball'}
                    subtitle={"Photos cultes"}
                />
            </Shape>
            <Staging></Staging>
        </>
    );
};

export default CultPhotos;