import React from 'react';
import bg1 from "../../../img/slider2.webp";
import SqbbPageTitle from "../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import SqbbShap from "../../../components/UI/SqbbShap/SqbbShap";
import {useLoaderData} from "react-router-dom";
import HomeArticleCard from "../../../components/Blog/HomeArticleCard/HomeArticleCard";
import HandleArticleCoverImg from "../../../core/HandleArticleCoverImg";

const Category = () => {
    const posts = useLoaderData();

    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(12, 38, 75, 0.4) 0%, rgba(12, 38, 75, 0.6) 35%, rgba(12, 38, 75, 1) 100%)`}
                backgroundImage={bg1}
                backgroundAttachment={'fixed'}
                backgroundPosition={'center bottom'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={`Categorie : ${posts[0]._embedded["wp:term"][0][0].name}`}></SqbbPageTitle>
            </SqbbShap>

            <div className={"container"} style={{marginBottom: "100px"}}>
                <div className={"row"}>
                    {posts.map(post => {
                        return <HomeArticleCard
                            id={ post.id }
                            bgImg={ HandleArticleCoverImg(post._embedded["wp:featuredmedia"], "attachment", "full")}
                            title={ post.title.rendered }
                            articleLink={`/actualites/article/${post.id}`}
                            key={ post.id}
                        />
                    })}
                </div>
            </div>
        </>
    );
};

export default Category;