import React from 'react';
import Period from "../Period/Period";
import Title from "../../UI/Title/Title";
import classes from "./Era.module.css";

const Era = (props) => {
    return (
        <div className="row">
            <div className="col-lg-12">

                <div className={classes.resume_title} style={{marginTop: '3em'}}>
                    <Title
                        title={props.era.eraMainTitle }
                        shadowTop={'-25px'}
                        shadowLeft={'-2em'}
                        shadowSize={'3em'}
                    />
                </div>
                {
                    props.era.era.map((period, index) => <Period period={ period } key={index}/>)
                }
            </div>
        </div>
    );
};

export default Era;