import React from 'react';
import classes from "./CashlessTitle.module.css";

const CashlessTitle = () => {
    return (
        <div className={`container`}>
            <div className={`row col-12`}>
                <h4 className={classes.cashless_title}>
                    Recharge ta carte ici
                </h4>
            </div>
        </div>
    );
};

export default CashlessTitle;