import palmares1987 from "../img/palmares/1987.webp";
import palmares1993 from "../img/palmares/1993.webp";
import palmares1995 from "../img/palmares/1995.webp";
import palmares1999 from "../img/palmares/2001.webp";
import palmares2001 from "../img/palmares/champion_n1_2001.webp";
import palmares2012 from "../img/palmares/2012.webp";
import palmares2019 from "../img/palmares/2019.webp";
import palmares2023 from "../img/palmares/2013.webp";


export const palmaresData = {
    pageTitle: {
        title: 'Palmarès du club',
        subtitle: 'Palmarès'
    },
    eras: [
        {
            eraMainTitle: 'Nos titres',
            era: [
                {
                    eraTitle: 'Vainqueur de la Coupe de France',
                    date: '1987',
                    photoLegend: '',
                    text: [
                        "Il s'agit du premier titre majeur pour la famille Saint-Quentin Basket-Ball et du premier titre d’un club picard en Coupe de France. Le SQBB a atteint les demi-finales en 1985 et la finale en 1987. Lors de la finale à Joigny (Yonne), l'équipe gagnante était soutenue par 150 supporters du SQBB."
                    ],
                    image: palmares1987
                },
                {
                    eraTitle: 'De la N1A à la Nationale 4',
                    date: '1993',
                    photoLegend: '',
                    text: [
                        "Le SQBB domine Strasbourg et confirmé son entrée en N1A. Malheureusement,\n" +
                        "après un bras de fer administratif, judiciaire et politique le 4 juin, le club est déclaré\n" +
                        "liquidé et rétrogradé en National 4.",
                    ],
                    image: palmares1993
                },
                {
                    eraTitle: 'Champion de Nationale 4',
                    date: '1995',
                    photoLegend: '',
                    text: [
                        "Bertrand Gamess emmène le SQBB vers le titre de champion de Nationale 4 en finale face au club de Mulhouse, un autre relégué financier."
                    ],
                    image: palmares1995
                },
                {
                    eraTitle: 'Champion de Nationale 1',
                    date: '1999',
                    photoLegend: '',
                    text: [
                        "Il n'a fallu que six ans à Robert Ducamp pour ramener le SQBB en deuxième\n" +
                        "division. C'est le grand entraîneur polonais de basket, Edouard Jurkiewicz, qui en a\n" +
                        "été l’acteur principal."
                    ],
                    image: palmares1999
                },
                {
                    eraTitle: 'Champion de Nationale 1',
                    date: '2001',
                    photoLegend: '',
                    text: [
                        "Après une première expérience décevante en Pro B, le club revient en N1, sous la houlette de Charlie Overey, et s'épanouit à nouveau avec un groupe de joueurs  centré autour de Thierry Pons, Alexandre Dudouet, Roderic Pegon et René Tony. Le SQBB est maintenant de nouveau en LNB."
                    ],
                    image: palmares2001
                },
                {
                    eraTitle: 'Champion de Nationale 1',
                    date: '2012',
                    photoLegend: '',
                    text: [
                        "Après trois ans de purgatoire, avec Alexandre Dudouet comme président et Thierry Pons comme manager général, le club revient en Pro B. Jimmal Ball et Darnell Williams étaient deux nouveaux joueurs importants de l’effectif champion. La dernière journée, l'équipe s’impose face à Brest."
                    ],
                    image: palmares2012
                },
                {
                    eraTitle: 'Vainqueur des Play-offs de Nationale 1',
                    date: '2019',
                    photoLegend: '',
                    text: [
                        "Le SQBB offrira au Havre un troisième match en finale des barrages N1 à l'issue du 45e match d'une interminable saison. 200 supporters étaient présents pour accompagner leur équipe fanion. Après un an à la présidence, Laurent Prache assainit les comptes et redonne de l’espoir aux amoureux du club."
                    ],
                    image: palmares2019
                },
                {
                    eraTitle: 'Champion de Pro B',
                    date: '2023',
                    photoLegend: '',
                    text: [
                        "Bon troisième en 2021 et en 2022, Saint-Quentin terrasse Angers lors de la dernière\n" +
                        "journée de 2023 dans un Palais des Sports à guichets fermés depuis deux mois et\n" +
                        "devient champion de Pro B pour la première fois de son histoire. Surtout, Julien\n" +
                        "Mahé, coach providentiel, renvoie le club au sommet du basket français, où il n’a\n" +
                        "plus mis les pieds depuis 1992."
                    ],
                    image: palmares2023
                },
            ]
        }
    ]
}