import React from 'react';
import CashlessHeader from "../../components/Cashless/CashlessHeader/CashlessHeader";
import CashlessTitle from "../../components/Cashless/CashlessTitle/CashlessTitle";
import CashlessForm from "../../components/Cashless/CashlessForm/CashlessForm";
import CashlessReassurance from "../../components/Cashless/CashlessReassurance/CashlessReassurance";
import CashlessWhat from "../../components/Cashless/CashlessWhat/CashlessWhat";

import CashlessQuestion from "../../components/Cashless/CashlessQuestion/CashlessQuestion";
import CashlessFaq from '../../components/Cashless/CashlessFaq/CashlessFaq';

const Cashless = () => {
    return (
        <>
            <CashlessHeader/>
            <CashlessTitle/>
            <CashlessForm/>
            <CashlessReassurance/>
            <CashlessWhat/>
            <CashlessFaq/>
            <CashlessQuestion/>
        </>
    );
};

export default Cashless;