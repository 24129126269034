import React from 'react';
import classes from "./PlayerResume.module.css";

const PlayerResume = ( props ) => {
    // Vérifie si player et carrier existent pour éviter les erreurs
    const { player } = props;
    const carrier = player?.carrier || {};
    const proCareer = carrier?.pro || [];
    const amateurCareer = carrier?.amateur || [];
    const palmares = carrier?.palmares || [];

    return (
        <div className="container">

            <div className="section-title">
                <h2>Carrière</h2>
                <p>En Club</p>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <h3 className={ classes.resume_title }>
                        Carrière Professionnelle
                    </h3>
                    {proCareer.length > 0 ? (
                        proCareer.map((step, index) => (
                            <div className={ classes.resume_item } key={index}>
                                <h4 className={ classes.team_name }> { step.club }</h4>
                                <h5 className={ classes.date_range }> { step.comeIn} - { step.ending }</h5>
                                <p><em>{ step.leagueName }</em></p>
                            </div>
                        ))
                    ) : (
                        <p>Aucune information de carrière professionnelle disponible.</p>
                    )}
                </div>

                <div className="col-lg-6">
                    <h3 className={ classes.resume_title }>Carrière universitaire ou amateur</h3>
                    {amateurCareer.length > 0 ? (
                        amateurCareer.map((step, index) => (
                            <div className={ classes.resume_item } key={index}>
                                <h4 className={ classes.team_name }> { step.club }</h4>
                                <h5 className={ classes.date_range }> { step.comeIn} - { step.ending }</h5>
                            </div>
                        ))
                    ) : (
                        <p>Aucune information de carrière amateur disponible.</p>
                    )}

                    <h3 className={classes.resume_title} style={{ marginTop: "2em" }}>Palmarès</h3>
                    {palmares.length > 0 ? (
                        palmares.map((step, index) => (
                            <div className={ classes.resume_item } key={index}>
                                <h4 className={ classes.team_name }>{ step.championchipTitle }</h4>
                                <h5 className={ classes.date_range }>{ step.year }</h5>
                                <p><em>{ step.championship }</em></p>
                            </div>
                        ))
                    ) : (
                        <p>Aucune information sur le palmarès disponible.</p>
                    )}
                </div>
            </div>

        </div>
    );
};

export default PlayerResume;
