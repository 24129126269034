import React from 'react';
import classes from "./AboutPlayer.module.css";
import { BiChevronRight } from 'react-icons/bi';
import shadowPlayer from "../../../img/players/shadow.png";
import {Img} from 'react-image';


const AboutPlayer = (props) => {

    const chevronStyle = {
        fontSize: "16px",
        marginRight: "5px",
        color: "#18d26e",
        lineHeight: "0",
        size: "30px"
    }

    return (
        <>
            <div className={`container`}>

                <div className="section-title">
                    <h2>A propos</h2>
                    <p>{ props.firstName } { props.lastName }</p>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <Img src={ props.profilImg ? props.profilImg : shadowPlayer } className={`img-fluid ${ classes.profil_img }`} alt=""/>
                    </div>
                    <div className={`col-lg-8 pt-4 pt-lg-0 ${ classes.content }`}>
                        <h3 className={classes.poste}>
                            { props.position }
                        </h3>
                        <p className="fst-italic">
                            { props.smallResume}
                        </p>
                        <div className="row">
                            <div className="col-lg-6">
                                <ul className={ classes.player_info_list }>
                                    <li className={ classes.player_info_list_item }>
                                        <BiChevronRight {...chevronStyle}></BiChevronRight>
                                        <strong>Date de naissance: &nbsp;</strong>
                                        <span> { props.birth } </span>
                                    </li>
                                    <li className={ classes.player_info_list_item }>
                                        <BiChevronRight {...chevronStyle}></BiChevronRight>
                                        <strong>Nationalité:&nbsp; </strong>
                                         <span> { props.country }</span>
                                    </li>
                                    <li className={ classes.player_info_list_item }>
                                        <BiChevronRight {...chevronStyle}></BiChevronRight>
                                        <strong>Numéro:&nbsp; </strong>
                                         <span> { props.number }</span>
                                    </li>
                                    <li className={ classes.player_info_list_item }>
                                        <BiChevronRight {...chevronStyle}></BiChevronRight>
                                        <strong>Lieu de naissance:&nbsp; </strong>
                                         <span> { props.birthPlace }</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-6">  
                                <ul className={ classes.player_info_list }>
                                
                                    <li className={ classes.player_info_list_item }>
                                        <BiChevronRight {...chevronStyle}></BiChevronRight>
                                        <strong>Taille: &nbsp;</strong>
                                         <span> {props.size}m</span>
                                    </li>
                                    <li className={ classes.player_info_list_item }>
                                        <BiChevronRight {...chevronStyle}></BiChevronRight>
                                        <strong>Poste: &nbsp;</strong>
                                        <span> { props.position }</span>
                                    </li>
                                    <li className={ classes.player_info_list_item }>
                                        <BiChevronRight {...chevronStyle}></BiChevronRight>
                                        <strong>Dans l'équipe depuis: &nbsp;</strong>
                                        <span> { props.teamPresence }</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p className={classes.biography}>
                            { props.resume }
                        </p>
                    </div>
                </div>

            </div>
        </>
    );
};

export default AboutPlayer;