import React, { useState } from "react";
import "./HomeSlider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { homeSlider } from "../../datas/homeSlider";
import { data } from "isotope-layout";
import HandleArticleCoverImg from "../../core/HandleArticleCoverImg";
import HtmlRenderer from "../../core/HtmlRenderer";

const HomeSlider = (latestPosts) => {

  let homeSlider = latestPosts.homeSlider.map((post) => {
    return {
      title: post.title.rendered,
      id: post.id,
      img: HandleArticleCoverImg(
        post._embedded["wp:featuredmedia"],
        "attachment",
        "full"
      ),
    };
  });

  const [slideBgImg, setSlideBgImg] = useState(homeSlider[0].img);

  const handleBgImg = (slide) => {
    const activeSlide = document.querySelector(".swiper-slide-active");
    const realActiveIndex = activeSlide.getAttribute("data-swiper-slide-index");
    const computeIndex = indexCorrector(+realActiveIndex);
    const activeSlideImg = homeSlider[computeIndex]
      ? homeSlider[computeIndex].img
      : homeSlider[0].img;
    setSlideBgImg((prevState) => activeSlideImg);
  };

  const indexCorrector = (index) => {
    if (index <= homeSlider.length - 1) {
      return index + 1;
    } else {
      return index;
    }
  };

  const swiperParams = {
    containerClass: "swiper-container",
    wrapperClass: "swiper-wrapper",
    slideClass: "swiper-slide",
    modules: [Autoplay, Pagination, Navigation],
    speed: 4000,
    loop: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true,
    },
    slidesPerView: "auto",
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    onSlideChange: (swiper) => {
      const indexDiapositiveActuelle = swiper.activeIndex; // Obtenir l'index de la diapositive actuelle
      console.log("Index de la diapositive actuelle :", indexDiapositiveActuelle);
      handleBgImg();
    },
    pagination: { clickable: false },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      480: {
        slidesPerView: 1,
        spaceBetween: 60,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 80,
      },
      992: {
        slidesPerView: 1,
        spaceBetween: 120,
      },
    },
  };

  return (
    <section id="why-us" className="why-us">
      <div className="container-fluid">
        <div className="row g-0">
          <div className="col-xl-6 ">
            <div className="img-bg" style={{backgroundImage: `url(${ slideBgImg })`}}></div>
          </div>

          <div className="col-xl-6 slides  position-relative">
            <Swiper {...swiperParams}>
              {homeSlider.map((slide, index) => {
                return (
                  <SwiperSlide virtualIndex={index} key={index}>
                    <div className="item">
                      <h4 className="mb-3" data-aos="fade-up">
                        <HtmlRenderer content={slide.title}></HtmlRenderer>
                      </h4>
                      {slide.text && <p data-aos="fade-down">{slide.text}</p>}
                      {slide.id && (
                        <a
                          href={`/actualites/article/${slide.id}`}
                          target={"_blank"}
                        >
                          En savoir plus
                        </a>
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </Swiper>
          </div>

          <div id="line1" style={{ right: "26%" }}></div>
          <div id="line2" style={{ right: "20%", left: "65%" }}></div>
        </div>
      </div>
    </section>
  );
};

export default HomeSlider;
