import React from 'react';
import './HtmlRenderer.css';
import sanitizeHtml from "sanitize-html";
const HtmlRenderer = ({ content }) => {
    const cleanedHtml = sanitizeHtml(content, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img', 'iframe', 'a', 'strong' ]),
        allowedAttributes: {
            iframe: ['src', 'width', 'height', 'frameborder', 'allowfullscreen'],
            img: [ 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading' ],
            a: [ 'href' ]
        }
    });
    return <div dangerouslySetInnerHTML={{ __html: cleanedHtml }}></div>
};

export default HtmlRenderer;