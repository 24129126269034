import React from 'react';
import classes from "./SqbbTitle.module.css";

const SqbbTitle = (props) => {
    return (
        <div className={classes.sqbb_title}>
            <h4 className={classes.sqbb_title_h} style={{color: props.color}}>{ props.title }</h4>
            <div className={ classes.sqbb_title_underline } style={{ textDecoration: props.underline }}></div>
        </div>
    );
};

export default SqbbTitle;