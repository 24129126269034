import React from 'react';
import classes from "./CashlessQuestion.module.css";

const CashlessQuestion = () => {
    return (
        <div className={`container`}>
            <div className={`row col-12`}>
                <div className={ classes.cashless_question_container }>
                    <div className={ classes.cashless_question }>
                        <div className={ classes.cashless_question_title }>
                            <span>Une autre question ?</span>
                        </div>
                        <div className={ classes.cashless_question_text }>
                            Envoyez nous un mail à contact@sqbb.fr<br/>
                            Pour objet : «QUESTION CASHLESS»
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CashlessQuestion;