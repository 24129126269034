import React from 'react';
import Era from "../Era/Era";
import "./EraContainer.module.css";
import classes from "./EraContainer.module.css";

const ErasContainer = (props) => {
    return (
        <>
            <section id="resume" className={classes.resume}>
                <div className="container">
                    {
                        props.eras.map((era, i) => {
                            return(
                                <Era era={era} key={i}/>
                            );
                        })
                    }
                </div>
            </section>
        </>
    );
};

export default ErasContainer;