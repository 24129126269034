import React, {useEffect} from 'react';
import { Outlet } from "react-router-dom";
import MainNavigation from "../components/MainNavigation/MainNavigation";
import PartnersSlider from "../components/Partners/PartnersSlider/PartnersSlider";
import AOS from 'aos';
import { UAParser } from "ua-parser-js";
import MobileMainNavigation from "../components/Mobile/MobileMainNavigation/MobileMainNavigation";

const Root = () => {
    const uaParser = new UAParser();

    useEffect(() => {
        AOS.init();
    })

    const forMobile = () => {
        if(["tablette", "mobile"].includes(uaParser.getDevice().type)){
            return <MobileMainNavigation></MobileMainNavigation>
        } else {
            return <MainNavigation/>
        }
    }

    return (
        <>
            { forMobile() }
            <main style={{textAlign: "center"}}>
                <Outlet/>
            </main>
            <PartnersSlider/>
        </>
    );
};

export default Root;