import React from 'react';
import classes from "./Period.module.css";
import {Img} from 'react-image';


const Period = (props) => {
    return (
        <div className={ classes.resume_item }>
            <div className={ classes.item_content } data-aos="fade-up-left" data-aos-anchor-placement="top-bottom" data-aos-duration="1500">
                <h4 className={ classes.period_title }>{ props.period.eraTitle }</h4>
                <h5 className={ classes.period_date }>{ props.period.date }</h5>
                <p><em>{ props.period.photoLegend }</em></p>
                <div className="row">
                    <div className="col-md-6">
                        <div className={ classes.history_img_box }>
                            <Img className={ classes.history_img } src={ props.period.image } alt={ props.period.eraTitle }/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {
                            props.period.text.map( (p, i) => <p key={i}>{ p }</p>)
                        }
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Period;