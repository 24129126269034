import React from 'react';
import Title from "../../UI/Title/Title";
import classes from "./Staging.module.css";
import bg2 from "../../../img/bg2.png";

const Staging = () => {
    const stagingTitleConfig = {
        shadowTop:'15px',
        shadowLeft:'10px',
        shadowColor:'#fff',
        shadowOpacity:'0.1',
        shadowSize:'3em',
        titleSize:'1.5em',
        titleColor:'#fff',
        title:'Retrouvez-nous plus tard ',
        subtitle: "Page en construction ..."
    }
    return (
        <div className={classes.staging_box} style={{background: `linear-gradient(45deg, rgba(15,66,138,0.9), rgba(15,66,138,0.5)), url(${ bg2 }) center center`}}>
            <Title { ...stagingTitleConfig }></Title>
        </div>
    );
};

export default Staging;