import React, {useEffect, useState} from 'react';
import {Link, NavLink, useLocation, useParams} from "react-router-dom";
import { UAParser } from "ua-parser-js";
import DesktopPlayer from "../../../components/Players/DesktopPlayer/DesktopPlayer";
import MobilePlayer from "../../../components/Players/MobilePlayer/MobilePlayer";
import { BiCaretLeftCircle } from "react-icons/bi";

const PlayerDetail = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])

    const style1 = {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        textAlign: "center",
        marginTop: "10px"
    }

    const style = {
        backgroundColor: "#fff",
        color: "#0F428A",
        border: "none",
        padding: "10px 20px",
        textTransform: "uppercase",
        borderRadius: "4px",
        fontFamily: "LTEnergy, sans-serif"
    }

    const uaParser = new UAParser();
    if (["tablette", "mobile"].includes(uaParser.getDevice().type)){
        return (
            <>
                <div className={"back_to_player"} style={style1}>
                    <Link to={"/equipe/pro/joueurs"}><button className={"back_to_player_btn"} style={style}><BiCaretLeftCircle size={"20px"}></BiCaretLeftCircle>Retour</button></Link>
                </div>
                <MobilePlayer></MobilePlayer>
            </>
        )
    } else {
        return (
            <DesktopPlayer></DesktopPlayer>
        )
    }

};

export default PlayerDetail;