import React from 'react';
import classes from "./CashlessFaq.module.css";

const CashlessFaq = () => {
    return (
        <div className={`container`}>
            <div className={`row`}>
                <div className={`col-12`}>
                    <div className={ classes.cashless_faq }>
                        <div className={ classes.faq_header }>
                            <h2 className={ classes.faq_title }>
                                faq
                            </h2>
                        </div>
                        <div className={ classes.faq_list } id={"faqlist"}>
                            <div  className={`accordion accordion-flush ${classes.faq_list}`}>
                                <div className={ classes.accordion_item }>
                                    <h3 className={`accordion-header ${classes.accordion_header}`}>
                                        <button className={`accordion-button collapsed ${ classes.accordion_button }`} type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                                            Pourquoi créer un compte cashless ?
                                        </button>
                                    </h3>
                                    <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className={`accordion-body ${ classes.accordion_body }` }>
                                            En créant un compte, vous pourrez :
                                            <ul>
                                                <li>- Consulter le solde de votre carte et l’historique des transactions</li>
                                                <li>- Enregistrer votre Carte Bancaire pour un rechargement plus rapide</li>
                                                <li>- Demander à bloquer votre carte et en obtenir une nouvelle en cas de perte ou de vol</li>
                                                <li>- Recharger avant le match via le site internet</li>
                                                <li>- Demander un reçu de vos consommations</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={`accordion accordion-flush ${classes.faq_list}`}>
                                <div className={ classes.accordion_item }>
                                    <h3 className={`accordion-header ${classes.accordion_header}`}>
                                        <button className={`accordion-button collapsed ${ classes.accordion_button }`} type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                                            Comment créer un compte Cashless ?
                                        </button>
                                    </h3>
                                    <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className={`accordion-body ${ classes.accordion_body }` }>
                                            Vous pouvez créer un compte après <b style={{fontWeight: "900", color: "#000", fontFamily: "NoirProRegular, sans-serif"}}>avoir récupéré votre carte cashless </b>:

                                            <ul>
                                                <li>1 - Saisissez votre adresse mail</li>
                                                <li>2 - Remplissez les champs demandés (nom, prénom, etc.)</li>
                                                <li>3 - Saisissez le numéro de votre support cashless, présent au dos de ce dernier</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={`accordion accordion-flush ${classes.faq_list}`}>
                                <div className={ classes.accordion_item }>
                                    <h3 className={`accordion-header ${classes.accordion_header}`}>
                                        <button className={`accordion-button collapsed ${ classes.accordion_button }`} type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                                            Comment recharger mon compte ?
                                        </button>
                                    </h3>
                                    <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className={`accordion-body ${ classes.accordion_body }` }>
                                            <b style={{fontWeight: "900", color: "#000", fontFamily: "NoirProRegular, sans-serif"}}>Avant les matchs,</b>
                                            vous pouvez recharger en ligne en vous créant un compte sur sqbb.fr, onglet
                                            «Cashless».
                                            <br/>
                                            <br/>
                                            <b style={{fontWeight: "900", color: "#000", fontFamily: "NoirProRegular, sans-serif"}}>Pendant les matchs,</b> vous pouvez recharger votre carte de trois façons :
                                            <ul>
                                                <li>- Avec votre smartphone sur le site internet sqbb.fr, onglet «Cashless».</li>
                                                <li>- Aux « Banque cashless » présentes à Pierre Ratte</li>
                                                <li>- En scannant le qr code présent sur votre carte (fast top up)</li>
                                            </ul>
                                            <br/>
                                            Vous pouvez recharger votre compte autant de fois que vous le souhaitez, avant et pendant l’événement.
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={`accordion accordion-flush ${classes.faq_list}`}>
                                <div className={ classes.accordion_item }>
                                    <h3 className={`accordion-header ${classes.accordion_header}`}>
                                        <button className={`accordion-button collapsed ${ classes.accordion_button }`} type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                                            Que se passe t-il si ma carte est volée ou perdue ?
                                        </button>
                                    </h3>
                                    <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className={`accordion-body ${ classes.accordion_body }` }>
                                            - Si vous avez créé un compte sur le site Internet, vous pourrez demander le blocage de votre carte
                                            en nous envoyant un mail à «contact@sqbb.fr». Selon ce modèle : <br/>
                                            <div style={{ paddingLeft: "25px" }}>
                                                <br/>
                                                <b style={{fontWeight: "900", color: "#000", fontFamily: "NoirProRegular, sans-serif"}}>Objet </b>: BLOQUAGE CARTE CASHLESS
                                                <br/>
                                                <b style={{fontWeight: "900", color: "#000", fontFamily: "NoirProRegular, sans-serif"}}>Corps mail :  </b>
                                                Suite à la perte/vol de ma carte cashless, je demande son bloquage le plus rapidement
                                                possible. Je reconnais par ailleurs que le montant présent sur ma carte lors de sa perte ne pourra
                                                m’être restitué.
                                                <br/>
                                                Nom + Prénom
                                                <br/>
                                                Copie d’une pièce d’identité en PJ
                                                <br/>
                                                Optionnel : code à 6 lettres présent au dos de votre carte.
                                                <br/>
                                                <br/>
                                            </div>

                                            La création de compte est nécessaire pour permettre le blocage de votre
                                            carte . Si vous n’avez pas créé de compte associé à votre carte, le blocage sera impossible.
                                            <br/>
                                            <br/>
                                            Vous pouvez recharger votre compte autant de fois que vous le souhaitez, avant et pendant l’événement.
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={`accordion accordion-flush ${classes.faq_list}`}>
                                <div className={ classes.accordion_item }>
                                    <h3 className={`accordion-header ${classes.accordion_header}`}>
                                        <button className={`accordion-button collapsed ${ classes.accordion_button }`} type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-5">
                                            Comment connaître le solde de ma carte ?
                                        </button>
                                    </h3>
                                    <div id="faq-content-5" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className={`accordion-body ${ classes.accordion_body }` }>
                                            Si vous avez créé un compte, vous pourrez consulter votre solde sur le site Internet sqbb.fr,
                                            onglet «Cashless».
                                            <br/><br/>
                                            Votre solde vous est également communiqué après chaque transaction passée
                                            <br/><br/>
                                            Vous pouvez demander votre solde à n’importe quel moment, sur n’importe quel point de vente,
                                            en scannant simplement votre carte.
                                            <br/><br/>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={`accordion accordion-flush ${classes.faq_list}`}>
                                <div className={ classes.accordion_item }>
                                    <h3 className={`accordion-header ${classes.accordion_header}`}>
                                        <button className={`accordion-button collapsed ${ classes.accordion_button }`} type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-6">
                                            Ai-je accès à un récapitulatif de mes transactions ?
                                        </button>
                                    </h3>
                                    <div id="faq-content-6" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className={`accordion-body ${ classes.accordion_body }` }>
                                            Oui, vous pouvez accéder au récapitulatif de vos transactions via votre compte sur le site Internet sqbb.fr,
                                            onglet «Cashless».
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={`accordion accordion-flush ${classes.faq_list}`}>
                                <div className={ classes.accordion_item }>
                                    <h3 className={`accordion-header ${classes.accordion_header}`}>
                                        <button className={`accordion-button collapsed ${ classes.accordion_button }`} type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-7">
                                            Puis-je avoir un reçu ?
                                        </button>
                                    </h3>
                                    <div id="faq-content-7" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className={`accordion-body ${ classes.accordion_body }` }>
                                            Oui, vous pouvez avoir un reçu via votre compte sur le site Internet sqbb.fr, onglet «Cashless».
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className={`accordion accordion-flush ${classes.faq_list}`}>
                                <div className={ classes.accordion_item }>
                                    <h3 className={`accordion-header ${classes.accordion_header}`}>
                                        <button className={`accordion-button collapsed ${ classes.accordion_button }`} type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-8">
                                            Comment prendre soin de ma carte ?
                                        </button>
                                    </h3>
                                    <div id="faq-content-8" className="accordion-collapse collapse" data-bs-parent="#faqlist">
                                        <div className={`accordion-body ${ classes.accordion_body }` }>
                                            Votre carte est équipée d’une puce électronique dans laquelle est stocké votre argent. Prenez-en soin
                                            pour éviter les désagréments ! Évitez toute exposition à la chaleur ou à une amme et ne tentez surtout
                                            pas de la percer : cela la rendrait immédiatement inopérante.                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default CashlessFaq;