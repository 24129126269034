import React from "react";
import classes from "./CashlessFaq.module.css";

const GeneralFaq = () => {
  return (
    <div className={`container`}>
      <div className={`row`}>
        <div className={`col-12`}>
          <div className={classes.cashless_faq}>
            <div className={classes.faq_header}>
              <h2 className={classes.faq_title}>infos pratiques</h2>
            </div>
            <div className={classes.faq_list} id={"faqlist"}>
              <div className={`accordion accordion-flush ${classes.faq_list}`}>
                <div className={classes.accordion_item}>
                  <h3
                    className={`accordion-header ${classes.accordion_header}`}
                  >
                    <button
                      className={`accordion-button collapsed ${classes.accordion_button}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-1"
                    >
                      Comment se procurer des billets pour les matchs ?
                    </button>
                  </h3>
                  <div
                    id="faq-content-1"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className={`accordion-body ${classes.accordion_body}`}>
                      Un lien de billetterie est publié sur nos réseaux sociaux
                      à 18h30 deux jours avant la date du match en question.
                      Vous ne trouverez pas d’accès à la billetterie sur ce site
                      web.
                    </div>
                  </div>
                </div>
              </div>

              <div className={`accordion accordion-flush ${classes.faq_list}`}>
                <div className={classes.accordion_item}>
                  <h3
                    className={`accordion-header ${classes.accordion_header}`}
                  >
                    <button
                      className={`accordion-button collapsed ${classes.accordion_button}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-2"
                    >
                      Où trouver la boutique en ligne du club ?
                    </button>
                  </h3>
                  <div
                    id="faq-content-2"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className={`accordion-body ${classes.accordion_body}`}>
                      La boutique en ligne est actuellement en construction.
                      Pour le moment, une boutique est à votre disposition lors
                      des soirs de match à Pierre Ratte.
                    </div>
                  </div>
                </div>
              </div>

              <div className={`accordion accordion-flush ${classes.faq_list}`}>
                <div className={classes.accordion_item}>
                  <h3
                    className={`accordion-header ${classes.accordion_header}`}
                  >
                    <button
                      className={`accordion-button collapsed ${classes.accordion_button}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-3"
                    >
                      À quelles heures sont les matchs du SQBB ?
                    </button>
                  </h3>
                  <div
                    id="faq-content-3"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className={`accordion-body ${classes.accordion_body}`}>
                      Le club connait la date de chaque rencontre 4 semaines à
                      l’avance. Voici les horaires courants pour un match prévu
                      pendant le week-end :
                      <ul>
                        <li>- Samedi à 16h00 / 18h30 / 21h00</li>
                        <li>- Dimanche à 14h30 / 16h30 / 19h00</li>
                      </ul>
                      &#9888; Certains matchs peuvent être programmés en semaine
                      ou exceptionnellement à des horaires différents le
                      week-end.
                    </div>
                  </div>
                </div>
              </div>

              <div className={`accordion accordion-flush ${classes.faq_list}`}>
                <div className={classes.accordion_item}>
                  <h3
                    className={`accordion-header ${classes.accordion_header}`}
                  >
                    <button
                      className={`accordion-button collapsed ${classes.accordion_button}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-4"
                    >
                      À quel prix peut-on acheter une place pour un match du
                      SQBB ?
                    </button>
                  </h3>
                  <div
                    id="faq-content-4"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className={`accordion-body ${classes.accordion_body}`}>
                      Les tarifs pour un match de championnat pour la saison
                      2023-2024 sont les suivants :
                      <br />
                      <div style={{ paddingLeft: "25px" }}>
                        <br />
                        <ul>
                          <li>
                            - 15€ en tribune centrale (12€ pour le tarif
                            réduit*)
                          </li>
                          <li>
                            - 12€ en tribune latérale (10€ pour le tarif
                            réduit*)
                          </li>
                          <li>- 5€ pour les enfants de 6 à 12 ans</li>
                          <li>- Gratuit pour les enfants de 5 ans et moins</li>
                        </ul>
                        <b
                          style={{
                            fontWeight: "900",
                            color: "#000",
                            fontFamily: "NoirProItalic, sans-serif",
                          }}
                        >
                          Tarifs réduits : Étudiant / 13-18 ans / Demandeur
                          d’emploi
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`accordion accordion-flush ${classes.faq_list}`}>
                <div className={classes.accordion_item}>
                  <h3
                    className={`accordion-header ${classes.accordion_header}`}
                  >
                    <button
                      className={`accordion-button collapsed ${classes.accordion_button}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-5"
                    >
                      Je viens avec un enfant de 5 ans ou moins, que dois-je
                      faire ?
                    </button>
                  </h3>
                  <div
                    id="faq-content-5"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className={`accordion-body ${classes.accordion_body}`}>
                      L’entrée est gratuite pour vos enfants de cinq ans ou
                      moins et devront prendre place sur vos genoux pendant
                      toute la durée du match. Lors de votre arrivée à Pierre
                      Ratte, merci de réclamer un billet pour votre enfant pour
                      que vous puissiez justifier de sa présence.
                      <br />
                      <b
                        style={{
                          fontWeight: "900",
                          color: "#000",
                          fontFamily: "NoirProItalic, sans-serif",
                        }}
                      >
                        &#9888; Vous devez pouvoir être en mesure de justifier
                        l’âge de votre enfant lors de votre arrivée à Pierre
                        Ratte.
                      </b>
                    </div>
                  </div>
                </div>
              </div>

              <div className={`accordion accordion-flush ${classes.faq_list}`}>
                <div className={classes.accordion_item}>
                  <h3
                    className={`accordion-header ${classes.accordion_header}`}
                  >
                    <button
                      className={`accordion-button collapsed ${classes.accordion_button}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-6"
                    >
                      Comment voir les matchs de SQBB à la télévision ?
                    </button>
                  </h3>
                  <div
                    id="faq-content-6"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className={`accordion-body ${classes.accordion_body}`}>
                      Tous les matchs de championnat du Saint-Quentin
                      Basket-Ball sont diffusés en direct et en exclusivité sur
                      l’application Skweek.tv disponible sur smartphone,
                      tablette et télévision connecté ou à l’adresse suivante :
                      <a href="https://www.skweek.tv/">Skweek.tv</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`accordion accordion-flush ${classes.faq_list}`}>
                <div className={classes.accordion_item}>
                  <h3
                    className={`accordion-header ${classes.accordion_header}`}
                  >
                    <button
                      className={`accordion-button collapsed ${classes.accordion_button}`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-content-7"
                    >
                      Comment postuler dans les catégories jeunes ?
                    </button>
                  </h3>
                  <div
                    id="faq-content-7"
                    className="accordion-collapse collapse"
                    data-bs-parent="#faqlist"
                  >
                    <div className={`accordion-body ${classes.accordion_body}`}>
                      <p>
                        {" "}
                        Vous êtes un jeune joueur qui souhaite rejoindre les
                        rangs des équipes jeunes SQBB-JSC ? Merci de vous rendre
                        sur le site de l'association <a href="http://www.sqbb-jsc.fr/inscriptions.html">(inscriptions au club et
                        licences )</a>.{" "}
                     
                      </p>
                      <p>
                        Les boîtes mails ainsi que les réseaux sociaux de
                        l'équipe professionnelle ne sont pas dédiés à ce genre
                        de démarche.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralFaq;
