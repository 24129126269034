import React from 'react';
import classes from "./CashlessReassuranceItem.module.css";
import recharge from "../../../../img/cashless/je-recharge.svg";
import aubar from '../../../../img/cashless/au-bar.svg';
import {Img} from 'react-image';
import profite from '../../../../img/cashless/je-profite.svg';

const CashlessReassuranceItem = () => {
    return (
        <div className={`container`}>
            <div className={`row`} style={{marginBottom: "70px"}}>
                <div className={`col-md-4`}>
                    <div className={ classes.item }>
                        <div className={ classes.item_header }>
                            <div className={ classes.item_header_number }>
                                <span>1</span>
                            </div>
                            <div className={ classes.item_header_title }>
                                <span style={{paddingTop: '50px'}}>Je recharge ma carte</span>
                            </div>
                        </div>

                        <div className={ classes.item_icon_bx }>
                            <Img src={ recharge } alt="Icone de recharge cashless" className={ classes.item_icon }/>
                        </div>

                        <div className={ classes.item_text_content }>
                            Via le lien ci-dessus (CB uniquement)
                            <br/>
                            À Pierre Ratte aux
                            différents points Cashless - Banque
                            (CB & Espèces)
                        </div>
                    </div>
                </div>

                <div className={`col-md-4`}>
                    <div className={ classes.item }>
                        <div className={ classes.item_header }>
                            <div className={ classes.item_header_number }>
                                <span>2</span>
                            </div>
                            <div className={ classes.item_header_title }>
                                <span style={{paddingTop: '50px'}}>Je paye simplement</span>
                            </div>
                        </div>

                        <div className={ classes.item_icon_bx }>
                            <Img src={ aubar } alt="Icone de recharge cashless" className={ classes.item_icon }/>
                        </div>

                        <div className={ classes.item_text_content }>
                            Aux bars &
                            points de restauration
                            « Biiiip » : Simple et rapide.
                            C’est payé !
                        </div>
                    </div>
                </div>

                <div className={`col-md-4`}>
                    <div className={ classes.item }>
                        <div className={ classes.item_header }>
                            <div className={ classes.item_header_number }>
                                <span>3</span>
                            </div>
                            <div className={ classes.item_header_title }>
                                <span style={{paddingTop: '50px'}}>Je <br/>profite</span>
                            </div>
                        </div>

                        <div className={ classes.item_icon_bx }>
                            <Img src={ profite } alt="Icone de recharge cashless" className={ classes.item_icon }/>
                        </div>

                        <div className={ classes.item_text_content }>
                            <p>
                                Bon appétit, à votre santé,
                                et bon match !
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CashlessReassuranceItem;