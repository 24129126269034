import React from 'react';
import './DiagonalContainer.css';

const DiagonalContainer = (props) => {
    return (
        <section id="diagonal_container" className="diagonal_container section-bg" style={{marginTop: '-10em'}}>
            <div className="container" data-aos="fade-up">
                { props.children }
            </div>
        </section>
    );
};

export default DiagonalContainer;