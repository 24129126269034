import React from 'react';
import classes from "./MatchGuide.module.css";
import guide2 from "../../../img/guide/guide2.png";
import guide3 from "../../../img/guide/guide3.png";
import guide4 from "../../../img/guide/guide4.png";
import guide5 from "../../../img/guide/guide5.png";
import guide6 from "../../../img/guide/guide6.png";
import guide7 from "../../../img/guide/guide7.png";
import SqbbPageTitle from "../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import ratte7 from "../../../img/pierreRatte/pierre_rate4.jpg";
import SqbbShap from "../../../components/UI/SqbbShap/SqbbShap";
import {Img} from 'react-image';

const MatchGuide = () => {
    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(12, 38, 75, 0.6) 0%, rgba(12, 38, 75, 0.8) 45%, rgba(12, 38, 75, 1) 100%)`}
                backgroundImage={ratte7}
                backgroundAttachment={'fixed'}
                backgroundPosition={'center bottom'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Guide match"} color={"#fff"}></SqbbPageTitle>
            </SqbbShap>
            <section id={classes.gallery} className={classes.gallery}>
                <div className="container-fluid">
                    <div className="row gy-4 justify-content-center">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className={`h-100 ${classes.gallery_item}`}>
                                <Img src={ guide2 } className={`img-fluid ${classes.guide_img}`} alt=""/>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className={`h-100 ${classes.gallery_item}`}>
                                <Img src={ guide3 } className={`img-fluid ${classes.guide_img}`} alt=""/>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className={`h-100 ${classes.gallery_item}`}>
                                <Img src={ guide4 } className={`img-fluid ${classes.guide_img}`} alt=""/>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className={`h-100 ${classes.gallery_item}`}>
                                <Img src={ guide5 } className={`img-fluid ${classes.guide_img}`} alt=""/>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className={`h-100 ${classes.gallery_item}`}>
                                <Img src={ guide6 } className={`img-fluid ${classes.guide_img}`} alt=""/>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className={`h-100 ${classes.gallery_item}`}>
                                <Img src={ guide7 } className={`img-fluid ${classes.guide_img}`} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MatchGuide;