import React from 'react';
import {NavLink} from "react-router-dom";
import SideBarRecentPost from "../SideBarRecentPost/SideBarRecentPost";

const BlogSideBar = (props) => {
    const {termCategory, termTag} = props

    return (
        <div className="sidebar" style={{background: "#fff"}}>

            <div className="sidebar-item categories">
                <h3 className="sidebar-title">Catégories</h3>
                <ul className="mt-3">
                    {
                        termCategory && termCategory.map(category => {
                            return (
                                <li style={{color: "#000"}} key={category.id}>
                                    <NavLink to={`/actualites/category/${ category.id }`}>
                                        { category.slug }
                                    </NavLink>
                                </li>
                            )
                        })
                    }
                    {
                        !termCategory && <li style={{color: "#000"}}>Pas de catégories </li>
                    }
                </ul>
            </div>

            {
                <SideBarRecentPost/>
            }

            <div className="sidebar-item tags">
                <h3 className="sidebar-title">Tags</h3>
                <ul className="mt-3">
                    {
                        termTag && termTag.map(tag => {
                            return(
                                <li key={tag.id}>
                                    <NavLink to={`/actualites/tag/${tag.id}`}>{tag.slug}</NavLink>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>

        </div>
    );
};

export default BlogSideBar;