import React from 'react';
import classes from "./TicketCard.module.css";
import {Img} from 'react-image';

const TicketCard = (props) => {
    return (
        <div className={ classes.ticket_container }>
            <div className={ classes.ticket_card }>
                <h2 className={ classes.match_title }>
                    <span>{ props.domicileTeamName }</span>
                    <span>{ props.externTeamName }</span>
                </h2>
                <i className={ classes.fa_arrow_right }></i>
                <p className={ classes.ticket_card_place }> { props.place }</p>
                <div className={ classes.pic }>
                    <div className={ classes.team_logo }>
                        <Img src={ props.domicileTeamLogo } alt=""/>
                    </div>
                    <div className={ classes.team_vs }>
                        VS
                    </div>
                    <div className={ classes.team_logo }>
                        <Img src={ props.externTeamLogo } alt=""/>
                    </div>
                </div>
                <ul className={ classes.ticket_card_ul }>
                    <li className={ classes.ticket_card_li }></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                    <li className={ classes.ticket_card_li } ></li>
                </ul>
                <div className={ classes.match_info }>
                    <p className={ classes.match_info_p}> { props.date } {props.month}</p>
                </div>
                <button className={ classes.ticket_card_button }>
                    <ion-icon name="arrow-redo-outline"></ion-icon>
                </button>
            </div>
        </div>
    );
};

export default TicketCard;