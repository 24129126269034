import React from 'react';
import {Img} from 'react-image';
import adminLogo from "../../../img/logo.png";

const PostAuthor = () => {
    return (
        <div className="post-author d-flex align-items-center" style={{background: "#fff"}}>
            <Img src={ adminLogo } className="flex-shrink-0" alt=""/>
            <div>
                <h4>Admin SQBB</h4>
                <div className="social-links">
                    <a href="https://twitters.com/#"><i className="bi bi-twitter"></i></a>
                    <a href="https://facebook.com/#"><i className="bi bi-facebook"></i></a>
                    <a href="https://instagram.com/#"><i className="biu bi-instagram"></i></a>
                </div>
            </div>
        </div>
    );
};

export default PostAuthor;