import React from 'react';
import './HistoryBloc.module.css';
import { Link } from "react-router-dom";
import firstTeam from "../../img/history/history_slider.webp";
import classes from "./HistoryBloc.module.css";

const HistoryBloc = () => {
    return (
        <section id={classes.history_bloc} className={classes.history_bloc}>
            <div className="container" data-aos="fade-up">

                <div className="row position-relative">

                    <div className={`col-lg-7 ${classes.about_img}`} style={{backgroundImage: `url(${ firstTeam })`}}></div>

                    <div className="col-lg-7">
                        <h2 className={classes.history_bloc_title}>Revivez l'histoire d'un club mythique</h2>
                        <div className={classes.our_story}>
                            <h4 className={classes.history_bloc_h4}>21 mai 1973</h4>
                            <h3 className={classes.history_bloc_h3}>Notre histoire</h3>
                            <p className={classes.history_bloc_paragraph}>
                                Saint-Quentin Basket-Ball est né le 21 mai 1973. Dans les locaux de l’office municipal des sports,
                                rue de la Comédie, l’Union sportive des Cheminots et l’Amicale Jumentier,
                                mal classés de l’Excellence régionale, tournent alors la page de l’ère des clubs de
                                quartier et des patronages pour fusionner et fonder une entité à vocation municipale.
                                C’est l’œuvre de Jean Cantineau, son président fondateur.
                            </p>
                            <p className={classes.history_bloc_paragraph}>
                                Malgré quelques réfractaires,
                                il a convaincu suffisamment d’adhérents au moment du vote. Nourri par sa connaissance de la discipline,
                                ses relations dans le milieu, et notamment son amitié avec Robert Busnel, le pape du basket français,
                                l’ancien instituteur devenu directeur du Centre d’information et d’orientation de Saint-Quentin, a donné l’impulsion.
                            </p>
                            <p className={classes.history_bloc_paragraph}>
                                Première sortie de Saint-Quentin Basket-Ball au tournoi de Chauny en septembre 1973.
                            </p>

                            <div className="d-flex align-items-center">
                                <Link to={'/club/histoire/saint-quentin'} className={`glightbox ${classes.history_bloc_btn}`}>En savoir plus</Link>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    );
};

export default HistoryBloc;