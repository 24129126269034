import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, EffectCoverflow, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow'
import './PlayersSlider.css';
import PlayerBox from "../PlayerBox/PlayerBox";
import {playersData} from "../../../datas/players"

const PlayersSlider = () => {
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        setPlayers(playersData)
    }, []);

    const swiperParams = {
        className: 'swiper-container',
        wrapperClass: 'swiper-wrapper',
        slideClass: 'swiper-slide',
        modules:[Autoplay, Pagination, Navigation, EffectCoverflow],
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "4",
        effect: "coverflow",
        coverflowEffect: {
            rotate: 30,
            stretch: 1,
            depth: 200,
            modifier: 1,
            slideShadows: true,
        },
        pagination: {
            el: ".swiper-pagination",
        },
    }

    return (
        <section id="team">
            <div className="content">
                <div className="imgBox">
                    <Swiper { ...swiperParams }>
                        {
                             players.map(player => {
                                return (
                                    <SwiperSlide key={player.id}>
                                        <PlayerBox
                                            img={player.profilImg}
                                            playerId={player.id}
                                            firstName={player.firstName}
                                            lastName={player.lastName}
                                        ></PlayerBox>
                                    </SwiperSlide>
                                );
                            })
                        }
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default PlayersSlider;