import React from 'react';
import ratte7 from "../../../img/pierreRatte/pierre_rate4.jpg";
import SqbbPageTitle from "../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import SqbbShap from "../../../components/UI/SqbbShap/SqbbShap";

const Benevoles = () => {
    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.4) 45%, rgba(255, 255, 255, 1) 100%)`}
                backgroundImage={ratte7}
                backgroundAttachment={'fixed'}
                backgroundPosition={'center center'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Bénévoles"} color={"#0c264b"}></SqbbPageTitle>
            </SqbbShap>
        </>
    );
};

export default Benevoles;