import React from 'react';
import cashlessHeader from '../../../img/cashless/logo-haut.svg';
import {Img} from 'react-image';
import classes from "./CashlessHeader.module.css";

const CashlessHeader = () => {
    return (
        <div className={`container`}>
            <div className={`row col-12`}>
                <div className={classes.cashless_header_img_bx}>
                    <Img src={cashlessHeader} style={{ maxWidth: '80%' }}  alt="Logo cashless"/>
                </div>
            </div>
        </div>
    );
};

export default CashlessHeader;