import React from 'react';
import HomeArticleCard from "../HomeArticleCard/HomeArticleCard";
import SqbbTitle from "../../UI/SqbbTitle/SqbbTitle";
import HandleArticleCoverImg from "../../../core/HandleArticleCoverImg";
import {UAParser} from "ua-parser-js";

const HomeArticleContainer = ({posts, uaParser}) => {

    console.log(posts);

    return (
        <div className={"home_article_container container"} style={{ height: "900px", marginTop: "4rem"}}>
            <div className={"row"}>
                <div className={"col-12"} style={{marginBottom: "3rem"}}>
                    <SqbbTitle
                        title={"Les Actus"} color={"#fff"}
                    />
                </div>
                {
                    uaParser.getDevice().type === "mobile" && posts &&
                    <HomeArticleCard
                        id={ posts[0].id }
                        bgImg={ HandleArticleCoverImg(posts[0]._embedded["wp:featuredmedia"], "attachment", "full")}
                        title={ posts[0].title.rendered }
                        articleLink={`/actualites/article/${posts[0].id}`}
                        key={ posts[0].id}
                    />
                }
                {
                    uaParser.getDevice().type !== "mobile" && posts && posts.map( post => {
                        return (
                            <HomeArticleCard
                                id={ post.id }
                                bgImg={ HandleArticleCoverImg(post._embedded["wp:featuredmedia"], "attachment", "full")}
                                title={ post.title.rendered }
                                articleLink={`/actualites/article/${post.id}`}
                                key={ post.id}
                            />
                        )
                    })
                }
            </div>
        </div>
    );
};

export default HomeArticleContainer;