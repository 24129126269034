import React, {useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from "swiper";
import './PartnersSlider.css';
import Title from "../../UI/Title/Title";
import {partnersData} from "../../../datas/partnersData";
import {Link} from "react-router-dom";
import classes from "../PartnerCard/PartnerCard.module.css";
import PartnersName from "../PartersName/PartnersName";
import SqbbTitle from "../../UI/SqbbTitle/SqbbTitle";
import {Img} from 'react-image';


const PartnersSlider = () => {
    const [ partners, setPartners] = useState(partnersData);

    const style = {
        minHeight: '60vh',
        background: '#fff',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'left',
        paddingTop: '5em'
    }

    const titleConfig = {
        shadowTop:'15px',
        shadowLeft:'10px',
        shadowColor:'#000',
        shadowOpacity:'0.1',
        shadowSize:'3em',
        titleSize:'2em',
        titleColor:'#0F428A',
        title:'Nos partenaires'
    }

    const swiperParams = {
     ///   containerClass: 'swiper-container',
        wrapperClass: 'swiper-wrapper',
        slideClass: 'swiper-slide',
        modules:[Autoplay],
        speed: 400,
        loop: true,
        autoplay: {
            delay: 2000,
            disableOnInteraction: false
        },
        slidesPerView: 'auto',
        breakpoints: {
            320: {
                slidesPerView: 2,
                spaceBetween: 40
            },
            480: {
                slidesPerView: 3,
                spaceBetween: 60
            },
            640: {
                slidesPerView: 4,
                spaceBetween: 80
            },
            992: {
                slidesPerView: 5,
                spaceBetween: 120
            }
        }
    }

    return (
        <>
            <div style={ style } className={'partners_slider_container'}>
                <div className="container" style={{marginBottom: '2em', textAlign:"center"}}>
                    <SqbbTitle title={"Nos partenaires"} color={"#0c264b"}></SqbbTitle>
                </div>

                <Swiper { ...swiperParams } >
                    { partners.map((partner, index) => (
                        <SwiperSlide key={ index }>
                            <a href={`${ partner.link }`} target='_blank'>
                                {
                                    partner.img
                                        ? <Img src={ partner.img } className="img-fluid" alt={ `logo ${ partner.name }` }/>
                                        : <PartnersName name={ partner.name }></PartnersName>
                                }
                            </a>
                        </SwiperSlide>
                    ))}
                </Swiper>

                <div className="btn-bx">
                    <Link to={'/partenaires/nos-partenaires'}><span>Espace partenaires</span></Link>
                </div>
            </div>
        </>
    );
};

export default PartnersSlider;