import jean from "../img/history/jean.webp"
import history from "../img/history/history_slider.webp";
import henry from "../img/history/henry_martin.webp";
import an1991 from "../img/history/1991.webp";
import an1993 from "../img/history/1993.webp";
import loupBlanc from "../img/history/loup_blanc.webp";
import nouvelEpopee from "../img/history/nouvelle_epopee.webp";

export const historyData = {
    pageTitle: {
        title: 'Histoire du club',
        subtitle: 'REVIVEZ L\'HISTOIRE D\'UN CLUB MYTHIQUE'
    },
    eras: [
        {
            eraMainTitle: 'Les débuts',
            era: [
                {
                    eraTitle: 'Naissance',
                    date: 'le 21 mai 1973',
                    photoLegend: 'De gauche à droite :Sylvie, Alain, Marie-Thérèse, Jean-Paul et Jean Cantineau',
                    text: [
                        "Saint-Quentin Basket-Ball est né le 21 mai 1973. Dans les locaux de l’office municipal des sports, rue de la Comédie, l’Union sportive des Cheminots et l’Amicale Jumentier, mal classés de l’Excellence régionale, tournent alors la page de l’ère des clubs de quartier et des patronages pour fusionner et fonder une entité à vocation municipale.",
                        "C’est l’œuvre de Jean Cantineau, son président fondateur. Malgré quelques réfractaires, il a convaincu suffisamment d’adhérents au moment du vote. Nourri par sa connaissance de la discipline, ses relations dans le milieu, et notamment son amitié avec Robert Busnel, le pape du basket français, l’ancien instituteur devenu directeur du Centre d’information et d’orientation de Saint-Quentin, a donné l’impulsion"
                    ],
                    image: jean
                },
                {
                    eraTitle: 'PREMIÈRE SORTIE DE SAINT-QUENTIN BASKET-BALL AU TOURNOI DE CHAUNY',
                    date: 'septembre 1973',
                    photoLegend: 'Bertrand Gamess emmène les juniors du lycée Henri-Martin vers le titre de champion de France scolaire puis à Den Helder, aux Pays-Bas (à droite) pour un tournoi européen',
                    text: [
                        "En quelques années, les résultats vont lui donner raison. Il confie ses équipes cadets, juniors et senior à Bertrand Gamess, un autre visionnaire. Le professeur de sports d’origine martiniquaise convainc les meilleurs espoirs du basket départemental (Jean-Guy Pellis, Jean-Luc Lorrain) de le rejoindre, il va les emmener bien plus loin qu’ils ne l’imaginent",
                        "Après plusieurs saisons achevées à la deuxième place du classement, c’est un autre président qui aide le club à franchir une nouvelle étape. Patron de la Caisse d’Epargne locale, Jean-Claude Chrétien fait financer en 1978 le recrutement d’un joueur américain. Depuis 1973, et l’arrivée du premier Américain en Picardie, au club voisin de Gauchy, l’équipe qui monte en Nationale 4 a survolé le championnat grâce à un joueur d’outre-Atlantique."
                    ],
                    image: history
                },
                {
                    eraTitle: 'BERTRAND GAMESS JETTE SON DÉVOLU SUR HENRY WRIGHT',
                    date: '1979 - 1985',
                    photoLegend: '',
                    text: [
                        "Ailier fort de 22 ans et 1,98m. Le déclic. Champion régional invaincu en 1979, Saint-Quentin Basket-Ball, qui a enfin obtenu le droit d’évoluer dans le palais des sports de la ville, le restera aussi en N4 la saison suivante. Accaparé par le lancement de la section sportive du lycée Henri-Martin, Bertrand Gamess cède son poste d’entraîneur deux saisons durant, puis revient lui faire franchir un palier de plus. Avec le renfort de Mike Gonsalves, et de quelques intérieurs antillais, les seniors accèdent en Nationale 2 en 1985.",
                        "Seule ombre au tableau, la défaite en finale de la coupe de France, à domicile devant 3000 spectateurs, contre le club de Hyères. Un mini-traumatisme qui sera réparé deux ans plus tard, lorsque le club ira soulever le trophée à Joigny (Yonne) aux dépens de Montpellier"
                    ],
                    image: henry
                },
            ]
        },
        {
            eraMainTitle: 'SQBB',
            era: [
                {
                    eraTitle: 'LE BASKET FRANÇAIS EST ALORS EN PLEINE MUTATION',
                    date: '1987 et 1988',
                    photoLegend: '',
                    text: [
                        "Il y a un chemin à se frayer jusqu’au plus haut niveau. Toujours sous l’impulsion de Jean-Claude Chrétien, son président, le club saint-quentinois poursuit l’intégration de basketteur d’origine américaine. Chris Singleton, Philippe Scholastique et bientôt Paul Fortier, propulsent la ville tout entière jusqu’au sommet. En douze mois, répartis entre les printemps 1987 et 1988, le SQBB, comme on l’appelle désormais, accèdent successivement en N1B puis en N1A ! Devenu entraîneur dans ce laps de temps, Chris Singleton embarque toute une ville, toute une région, dans une folle aventure.",
                        "Au terme d’une série de neuf victoires, dès sa première saison parmi l’élite, Saint-Quentin accroche même la deuxième du classement à mi-parcours, derrière l’intouchable CSP Limoges. Lors de la venue d’Orthez, le 10 décembre 1988, le Palais des sports enregistre son record d’affluence : 5206 spectateurs. Des normes de sécurité seront dès lors imposées pour limiter officiellement la capacité à 3600 personnes. Passé tout près de la qualification européenne, le club désormais présidé par Raymond Ducamp finit par la décrocher à l’issue de sa 3e saison en N1A. Mais, notamment en enrôlant la paire US David Thirdkill – José Vargas, il a dépensé sans compter pour affronter le Panathinaïkos d’Athènes en octobre 1991.",
                        "Financièrement exsangue, le SQBB est relégué en N1B en 1992. Et même si elle parvient, sur le terrain, à regagner son ticket pour la série A1, nouvelle appellation de la N1A, sa section professionnelle est mise en liquidation à l’été 1993. L’entité amateurs doit reprendre les rênes du club en Nationale 4."
                    ],
                    image: an1991
                },
                {
                    eraTitle: 'NOUVELLE ÈRE',
                    date: '1992 - 1993',
                    photoLegend: 'Debout de gauche à droite : Bernard Moronval (entraîneur), Denis Poyol (assistant), Arnaud Kamezac, François Petit, Olivier Hergott, Willie McDuffie, Olivier Mériguet, Larry Lawrence, Bernard Roger (dirigeant), Johnnie James, Claude Watremez (dirigeant), Laurent Van Wysberg , Alain Bogaert (président) ; accroupis de gauche à droite : Pat Taylor, Christian Ortega, Christian Cléante, Bruno Rodriguez.',
                    text:[
                        "C’est alors une nouvelle ère qui commence. Elu président, Robert Ducamp rebâtit alors sur les fondations et sur un public toujours aussi demandeur. Avec les jeunes du club, et Bertrand Gamess, revenu donner un coup de main, SQBB devient pour la première fois champion de France de N4 en 1995. En six ans seulement, les Bleu et Blanc retrouvent même la deuxième division. Champion de N1 en 1999 avec Edouard Jurkiewicz comme entraîneur, puis en 2001 avec Charlie Auffray, Saint-Quentin s’installe en Pro B pour huit années.",
                        "Sous l’impulsion d’Olivier Hirsch, il y dispute deux demi-finales de play-offs consécutifs contre Orléans (2006) puis Quimper (2007), mais peine à prendre le virage qui s’impose alors au haut niveau du basket français. Relégué en Nationale 1 en 2009, le club picard y passe trois saisons avant de décrocher un nouveau titre sur les ailes de Jimmal Ball et Darnell Williams. Le club est désormais entraîné par Sébastien Lambert, l’enfant du pays, autrefois recruté au club voisin de Gauchy par Chris Singleton."
                    ],
                    image: an1993
                },
                {
                    eraTitle: 'REVENU EN PRO B',
                    date: '2019',
                    photoLegend: '',
                    text: [
                        "SQBB ne parvient toutefois pas à y accrocher les play-offs, et descend de nouveau en N1 en 2017. Il n’y restera que deux saisons. La présidence désormais confiée à Laurent Prache, patron d’un des plus importants partenaires du club, l’hypermarché Leclerc, Saint-Quentin retrouve la Pro B en 2019, au terme de play-offs complètement fous achevés par une belle remportée au Havre. En 2020, la ligue nationale de basket décrète une saison blanche après son interruption par la crise du Covid-19.",
                        "Avant-dernier, le SQBB bénéficie d’une chance supplémentaire, qu’elle ne se prive pas de faire fructifier. Encadrée par Julien Mahé, l’équipe qui visait le maintien, créé la surprise en se mêlant à la lutte pour le titre 2021. Et termine finalement 3e du classement tandis que la LNB attribue tous les trophées individuels au SQBB : Julien Mahé meilleur entraîneur, Parker Jackson Cartwright meilleur joueur et Hugo Besson meilleur espoir du championnat ! Toujours porté par un public qui n’a que peu d’égal en France, le SQBB est de retour parmi les clubs qui comptent en LNB !"
                    ],
                    image: loupBlanc
                },
                {
                    eraTitle: 'vivre une nouvelle grande époque',
                    date: '2023',
                    photoLegend: '',
                    text: [
                        "Après une belle 3ème place en 2021, Saint-Quentin et Julien Mahé repartent à l’assaut de la\n" +
                        "deuxième division française avec comme objectif de continuer cette montée en puissance instaurée\n" +
                        "depuis l’arrivée du breton à la tête de l’équipe. Emmené par des joueurs comme Kendall Anthony ou\n" +
                        "son capitaine Benoit Gillet, le SQBB honore ses couleurs et réalise une saison pleine lui permettant\n" +
                        "de retrouver les playoffs de PRO B, malgré une élimination prématurée face à la JAV.\n" +
                        "Après 2 ans prometteurs, Saint-Quentin aborde la saison de PRO B 2022-2023 en réel outsider. En\n" +
                        "quête de playoffs pour une seconde année consécutive, Julien Mahé et ses joueurs réaliseront une\n" +
                        "saison parfaite. Le SQBB ne perdre qu’un seul des dix-sept matchs disputés à domicile et\n" +
                        "accrochera, lors de la toute dernière journée, le titre de champion de France de PRO B synonyme de\n" +
                        "montée en première division.\n" +
                        "C’est donc après 30 ans d’absence, et 50 ans d’existence, que le Saint-Quentin Basket Ball retrouve\n" +
                        "le gratin du basket français. Le club et ses phénix sont maintenant prêts à écrire une nouvelle page\n" +
                        "de leur histoire, à vivre une nouvelle grande époque.\n"
                    ],
                    image: nouvelEpopee
                }
            ]
        }
    ]
}