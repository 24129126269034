import React from 'react';
import CashlessReassuranceItem from "./CashlessReassuranceItem/CashlessReassuranceItem";

const CashlessReassurance = () => {
    return (
        <div>
            <CashlessReassuranceItem/>
        </div>
    );
};

export default CashlessReassurance;