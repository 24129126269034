import React from 'react';
import classes from "./CashlessWhat.module.css";
import { HiChevronRight } from "react-icons/hi";

const CashlessWhat = () => {
    return (
        <div className={`container`}>
            <div className={`row`}>
                <div className={`col-12`} style={{marginTop: "5em"}}>
                    <div className={ classes.cashless_section_header }>
                        <h3 className={ classes.cashless_section_header_title }>Le cashless c'est quoi ?</h3>
                        <div className={ classes.cashless_section_header_separator }>
                            <div className={ classes.separator_line }> </div>
                        </div>
                    </div>
                    <div>
                        <ul>
                            <li className={ classes.cashless_section_list_li }>
                                <HiChevronRight/>
                                Le cashless est l'unique moyen de régler vos achats lors des matchs (hors bar RDC & boutique des groupes de
                                supporters). Pour payer votre commande, il vous suffit de passer votre carte sur le terminal de paiement.
                            </li>

                            <li className={ classes.cashless_section_list_li }>
                                <HiChevronRight/>
                                Notre bénévole vous présentera le montant de la commande avant de taper votre carte. Votre nouveau solde
                                après transaction vous sera également communiqué.
                            </li>

                            <li className={ classes.cashless_section_list_li }>
                                <HiChevronRight/>
                                Le cashless permet de recharger votre compte avant ou pendant les matchs sur le site internet du
                                SQBB, onglet «Cashless». Vous pouvez de plus connaitre votre solde en temps réel en vous connectant sur votre
                                compte personnel
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={`col-12`} style={{marginTop: "5em"}}>
                    <div className={ classes.cashless_section_header }>
                        <h3 className={ classes.cashless_section_header_title }>
                            Pourquoi pre-charger sa carte ?
                        </h3>
                        <div className={ classes.cashless_section_header_separator }>
                            <div className={ classes.separator_line }> </div>
                        </div>
                    </div>
                    <div>
                        <ul>
                            <li className={ classes.cashless_section_list_li }>
                                <HiChevronRight/>
                                Le cashless est l'unique moyen de régler vos achats lors des matchs (hors bar RDC & boutique des groupes de
                                supporters). Pour payer votre commande, il vous suffit de passer votre carte sur le terminal de paiement.
                            </li>

                            <li className={ classes.cashless_section_list_li }>
                                <HiChevronRight/>
                                Notre bénévole vous présentera le montant de la commande avant de taper votre carte. Votre nouveau solde
                                après transaction vous sera également communiqué.
                            </li>

                            <li className={ classes.cashless_section_list_li }>
                                <HiChevronRight/>
                                Le cashless permet de recharger votre compte avant ou pendant les matchs sur le site internet du
                                SQBB, onglet «Cashless». Vous pouvez de plus connaitre votre solde en temps réel en vous connectant sur votre
                                compte personnel
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CashlessWhat;