import React from 'react';
import './CTAContainer.css';
import {Link} from "react-router-dom";

import bg1 from "../../img/slider2.webp";

const CtaContainer = () => {
    return (
        <section style={{position: "relative"}}>
            <div id="cta_container" className="cta_container section-bg" style={{background: `linear-gradient(45deg, rgba(15,66,138,0.9), rgba(15,66,138,0.5)), url(${ bg1 }) center center fixed`}}>
                <div className="container" data-aos="fade-up">

                    <div className="row no-gutters">
                        <div className="content col-xl-5 d-flex align-items-stretch">
                            <div className="content">
                                <h3>Devenez partenaire</h3>
                                <p>
                                    Vivez dès maintenant de fortes émotions entre collaborateurs en assistant à un spectacle
                                    unique et en faisant découvrir à vos clients et prospects l’ambiance exceptionnelle de Pierre-Ratte !
                                </p>
                                <p>
                                    Encouragez le Saint-Quentin Basket Ball et intégrez dès maintenant ce qui fait la force du club : le 6ème homme.
                                </p>
                                <Link to={'/partenaires/devenir-partenaire'} className="cta_container-btn"><span>Comment devenir partenaire ?</span></Link>
                            </div>
                        </div>
                        <div className="col-xl-7 d-flex align-items-stretch">
                            <div className="icon-boxes d-flex flex-column justify-content-center">
                                <div className="row">
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                                        <i className="bx bx-receipt"></i>
                                        <h4>Vivez une expérience unique</h4>
                                        <p>
                                            En devenant partenaire du SQBB, offrez-vous l'opportunité de vivre une expérience unique en vous impliquant dans le monde passionnant du basket professionnel.
                                        </p>
                                    </div>
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                                        <i className="bx bx-cube-alt"></i>
                                        <h4>S’ASSOCIER À L’IMAGE DU CLUB</h4>
                                        <p>
                                            En collaborant avec le SQBB, offrez-vous
                                            l'opportunité d'être associer à l'image d'un club évoluant dans l’Élite
                                            du basket français, et d’ainsi renforcer votre image de marque.
                                        </p>
                                    </div>
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                                        <i className="bx bx-images"></i>
                                        <h4>SOUTENIR UN PROJET AMBITIEUX</h4>
                                        <p>Votre soutien
                                            contribuera directement à notre développement, nous permettant de continuer à progresser
                                            et à représenter fièrement notre ville et notre région.</p>
                                    </div>
                                    <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                                        <i className="bx bx-shield"></i>
                                        <h4>DES PRESTATIONS ADAPTÉES À VOS BESOINS</h4>
                                        <p>En tant que partenaire du SQBB, nous vous offrons des prestations sur mesure, adaptées à
                                            vos besoins spécifiques en termes d’hospitalités, d’événementiel, de visibilité, de marketing
                                            et de relations publiques.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default CtaContainer;