import React from 'react';
import Shape from "../../../components/UI/Shap/Shape";
import Title from "../../../components/UI/Title/Title";
import Staging from "../../../components/NotFound/Staging/Staging";
import bg1 from "../../../img/slider2.webp";

const ParquetAccess = () => {
    return (
        <>
            <Shape
                height={'40vh'}
                backgroundColor={'linear-gradient(to right, rgba(15,66,139,0.6), rgba(15,66,139,0.6))'}
                backgroundImage={bg1}
                backgroundSize={'cover'}
                backgroundPosition={'center bottom'}
                backgroundAttachment={'fixed'}
            >
                <Title
                    title={ 'Infos Pratiques' }
                    subtitle={ "Accès Palais des sports Pierre Rate" }
                />
            </Shape>
            <Staging></Staging>
        </>
    );
};

export default ParquetAccess;