import React from 'react';
import classes from "./SqbbShap.module.css";

const SqbbShap = (props) => {
    const style = {
        height: props.height,
        background: `${props.backgroundColor}, url(${props.backgroundImage}) ${props.backgroundRepeat} ${props.backgroundAttachment} ${props.backgroundPosition}`,
        backgroundSize: props.backgroundSize,
        zIndex: 10
    }

    return (
        <div className={classes.shape} style={style}>
            <div className={classes.shape_content}>
                { props.children }
            </div>
        </div>
    );
};

export default SqbbShap;