import React, {useState} from 'react';
import { playersData } from "../../../datas/players";
import './Players.css';
import PlayersSlider from "../../../components/Players/PlayersSlider/PlayersSlider";
import SqbbPageTitle from "../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import SqbbShap from "../../../components/UI/SqbbShap/SqbbShap";
import team from "../../../img/players/Photo_equipe.webp";

const Players = () => {
    const [players, setPlayers] = useState( playersData );

    return (
        <>
            <SqbbShap
                height={'75vh'}
                backgroundColor={`linear-gradient(rgba(12, 38, 75, 0.6) 0%, rgba(12, 38, 75, 0.8) 45%, rgba(12, 38, 75, 1) 100%)`}
                backgroundImage={team}
                backgroundAttachment={'fixed'}
                backgroundPosition={'top center'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Nos joueurs"} color={"#fff"}></SqbbPageTitle>
            </SqbbShap>
            <PlayersSlider></PlayersSlider>
        </>
    );
};

export default Players;