import React from 'react';
import classes from "./Title.module.css";

const Title = (props) => {
    const titleShadowStyle = {
        top: props.shadowTop ? props.shadowTop : '-15px',
        left: props.shadowLeft ? props.shadowLeft : '50px',
        color: props.shadowColor ? props.shadowColor : '#f4f4f4',
        opacity: props.shadowOpacity ? props.shadowOpacity : '0.2',
        fontSize: props.shadowSize ? props.shadowSize : '4em',
    }

    const titleStyle = {
        fontSize: props.titleSize,
        color: props.titleColor
    }

    return (
        <div className={ classes.sectionTitle }>
            <span className={ classes.titleShadow } style={ titleShadowStyle }>{ props.title }</span>
            <h2 className={classes.title} style={ titleStyle }>{ props.title }</h2>
            { props.subtitle  && <p className={ classes.subtitle }>{ props.subtitle }</p> }
        </div>
    );
};

export default Title;