import React, {useEffect, useState} from 'react';
import './MobilePlayer.css';
import {Link, useLocation, useParams} from "react-router-dom";
import { playersData } from "../../../datas/players";
import AOS from 'aos';
import AboutPlayer from "../../PlayerDetails/AboutPlayer/AboutPlayer";
import PlayerResume from "../../PlayerDetails/PlayerResume/PlayerResume";
import {BiChevronRight} from "react-icons/bi";

const MobilePlayer = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname])

    useEffect(() => {
        AOS.init();
    })

    const params = useParams();
    const getCurrentPlayer = () => {
        return playersData.find(player => player.id.toString() === params.id.toString());
    }

    const [ currentPlayer, setCurrentPlayer ] = useState( getCurrentPlayer() );

    useEffect(() => {
        setCurrentPlayer(() => {
            return playersData.find(player => player.id.toString() === params.id.toString());
        })
    }, [ params.id ]);

    return (
        <main id="mobile_player_main">
            <section className={"mobile_about_player"}>
                <AboutPlayer {...currentPlayer}></AboutPlayer>
            </section>

            <section className={"mobile_resume_player"}>
                <PlayerResume player={currentPlayer}/>
            </section>

        </main>
    );
};

export default MobilePlayer;