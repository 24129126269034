import mairiesq from "../img/partners/saint-quentin.webp";
import hautfrance from "../img/partners/hauts-de-france.webp";
import aisne from "../img/partners/logo_aisne.webp";
import leclerc from "../img/partners/leclerc.webp";
import kfc from "../img/partners/kfc.webp";
import edf from "../img/partners/edf.webp";
import aesio from "../img/partners/aesio.webp";
import engie from "../img/partners/engie.webp";
import agence0001 from "../img/partners/0001.webp";
import leduc from "../img/partners/leduc-pascal.webp";
import leichman from "../img/partners/leichman.webp";
import topvandooren from "../img/partners/top-van-dooren.webp";
import aksis from "../img/partners/aksis.webp";
import assistdom from "../img/partners/assist-dom-services.webp";
import athies from "../img/partners/athies.webp";
import avivaAbeille from "../img/partners/abeille.webp";
import axaeirl from "../img/partners/axa.webp";
import batitech from "../img/partners/bati-tech.webp";
import bauarchitectes from "../img/partners/bau-architectes.webp";
import blondelLogistic from "../img/partners/blondel.webp";
import bonduelle from "../img/partners/bonduelle.webp";
import colleco from "../img/partners/colleco.webp";
import cpservices from "../img/partners/cp-services.webp";
import cuisinella from "../img/partners/cuisinella.webp";
import delarte from "../img/partners/del-arte.webp";
import eiffageroute from "../img/partners/eiffage-route.webp";
import engiehomeservices from "../img/partners/engie-home-services.webp";
import ganassurances from "../img/partners/gan.webp";
import gepelec from "../img/partners/gepelec.webp";
import gestpel from "../img/partners/gestpal.webp";
import gfautomobile from "../img/partners/gf-premium.webp";
import gorez from "../img/partners/gorez.webp";
import ironbody from "../img/partners/iron-body.webp";
import isolpicardie from "../img/partners/isol-picardie.webp";
import jeffdebruge from "../img/partners/jeff-de-bruges.webp";
import lecocteau from "../img/partners/le-cocteau.webp";
import leriche from "../img/partners/leriche.webp";
import toquesgourmandes from "../img/partners/les-toques-gourmandes.webp";
import lustral from "../img/partners/lustral.webp";
import maryauto from "../img/partners/mary.webp";
import nissan from "../img/partners/nissan.webp";
import orpi from "../img/partners/orpi.webp";
import pms from "../img/partners/pms.webp";
import renobat from "../img/partners/renobat.webp";
import rohant from "../img/partners/rohant-solaire.webp";
import rta from "../img/partners/rta.webp";
import sogapex from "../img/partners/sogapex.webp";
import sti from "../img/partners/sti.webp";
import transdev from "../img/partners/transdev.webp";
import veolia from "../img/partners/veolia.webp";
import volksvagen from "../img/partners/VOLKSWAGEN.webp";
import soreval from "../img/partners/SOREVAL-HABITAT.webp";
import ecaa from "../img/partners/ECAA.webp";
import lavalim from "../img/partners/lav-alim.webp";
import leclercAube from "../img/partners/leclerc-bar-sur-aube.webp";
import leclercChateau from "../img/partners/leclerc-chateau-thierry.webp";
import leclercLaon from "../img/partners/leclerc-laon.webp";
import leclercPierry from "../img/partners/leclerc-pierry.webp";
import eiffageConstruction from "../img/partners/eiffage-construction.webp"
import boucherie from "../img/partners/boucherie-leblant.webp";
import boulanger from "../img/partners/boulanger.webp";
import cehautfrance from '../img/partners/caisse-epargne.webp';
import champagnerepro from '../img/partners/champagnerepro.webp';
import cmdiag from '../img/partners/cm-diag.webp';
import dalkia from '../img/partners/dalkia.webp';
import danielmoquet from '../img/partners/daniel-moquet.webp';
import drain from '../img/partners/drain.webp';
import eav from '../img/partners/eav.webp';
import engiehome from '../img/partners/engie-home-services.webp';
import eurovia from '../img/partners/eurovia.webp';
import guidez from '../img/partners/guidez.webp';
import ibis from '../img/partners/ibis.webp';
import issilens from '../img/partners/isillens.webp';
import leclercplessis from '../img/partners/leclerc-LE-PLESSIS.webp';
import leclercmeaaux from '../img/partners/leclerc-MEAUX.webp';
import leclercvermelles from '../img/partners/leclerc-VERMELLE.webp';
import leclercviolaines from '../img/partners/leclerc-violaines.webp';
import leclercvitry from '../img/partners/leclerc-VITRYLEFRANCOIS.webp';
import nge from '../img/partners/nge.webp';
import outch from '../img/partners/outch.webp';
import signature from '../img/partners/signature.webp';
import vitamine from '../img/partners/vitamineT.webp';
import decatchlon from '../img/partners/decathlon.webp';
import gmconcept from '../img/partners/gm.webp';
import parbrise from '../img/partners/123.webp';
import froidpicard from '../img/partners/le-froid.webp';
import madeinfrance from '../img/partners/made-in-france.webp';
import convergence from '../img/partners/convergence.webp';
import mkenegies from '../img/partners/Logo_MK_Energies.png';

export const partnersData = [
    {
        name: 'AESIO',
        type: 'major',
        link: 'https://agences.aesio.fr/157-saint-quentin-mareuil?utm_source=gmb',
        img: aesio
    },
    {
        name: 'Boulanger',
        type: 'major',
        link: 'https://www.boulanger.com/magasins/hauts-de-france/aisne/fayet/route-nationale-29-parc-d-activite-commercial',
        img: boulanger
    },
    {
        name: 'E LECLERC',
        type: 'major',
        link: 'https://www.e.leclerc/',
        img: leclerc
    },
    {
        name: 'EDF',
        type: 'major',
        link: 'https://www.edf.fr/',
        img: edf
    },
    {
        name: 'CAISSE D’ÉPARGNE',
        type: 'major',
        link: 'https://www.agences.caisse-epargne.fr/banque-assurance/saint-quentin-centre-id16275002601',
        img: cehautfrance
    },
    {
        name: 'ENGIE',
        type: 'major',
        link: 'https://particuliers.engie.fr/',
        img: engie
    },
    {
        name: 'KFC',
        type: 'major',
        link: 'https://www.kfc.fr/',
        img: kfc
    },
    {
        name: 'Eurovia',
        type: 'classic',
        link: 'https://www.eurovia.fr/',
        img: eurovia
    },
    {
        name: 'LUSTRAL',
        type: 'classic',
        link: 'https://www.lustral.fr/',
        img: lustral
    },
    {
        name: 'EIFFAGE CONSTRUCTION',
        type: 'classic',
        link: 'https://www.eiffageconstruction.com/',
        img: eiffageConstruction
    },
    {
        name: 'MAIRIE SAINT QUENTIN',
        type: 'public',
        link: 'https://www.saint-quentin.fr/',
        img: mairiesq
    },
    {
        name: 'HAUTS DE FRANCE',
        type: 'public',
        link: 'https://www.hautsdefrance.fr//',
        img: hautfrance
    },
    {
        name: 'Le Conseil Départemental de l\'Aisnes 02',
        type: 'public',
        link: 'https://aisne.com/',
        img: aisne
    },
    {
        name: '0001',
        type: 'classic',
        link: 'https://0001.fr/',
        img: agence0001
    },
    {
        name: 'ACP',
        type: 'classic',
        link: '',
        img: ''
    },
    {
        name: '123 Pare-Brise',
        type: 'classic',
        link: 'https://www.123parebrise.fr/',
        img: parbrise
    },
    {
        name: 'AEC',
        type: 'classic',
        link: 'http://aec-laurent.fr/',
        img: ''
    },
    {
        name: 'AKSIS',
        type: 'classic',
        link: 'https://www.aksis.fr/nos-agences/detail/agence-saint-quentin',
        img: aksis
    },
    {
        name: 'ASSIST DOM SERVICES',
        type: 'classic',
        link: 'https://assistdomservices.site-solocal.com/',
        img: assistdom
    },
    {
        name: 'ATHIES BATIMENT',
        type: 'classic',
        link: 'https://www.athies-batiment.fr/',
        img: athies
    },
    {
        name: 'AVIVA ABEILLE',
        type: 'classic',
        link: 'https://agences.abeille-assurances.fr/saint-quentin/',
        img: avivaAbeille
    },
    {
        name: 'AXA EIRL SAVOYEN',
        type: 'classic',
        link: 'https://agence.axa.fr/hauts-de-france/aisne/saint-quentin/hugues-savoyen',
        img: axaeirl
    },
    {
        name: 'BÂTI TECH',
        type: 'classic',
        link: 'https://be-batitech.fr/',
        img: batitech
    },
    {
        name: 'B.A.U. Architectes',
        type: 'classic',
        link: 'https://www.bauarchitectes.com/',
        img: bauarchitectes
    },
    {
        name: 'BBA',
        type: 'classic',
        link: '',
        img: ''
    },
    {
        name: 'BLONDEL LOGISTIQUE',
        type: 'classic',
        link: 'https://www.groupe-blondel.com/',
        img: blondelLogistic
    },
    {
        name: 'BONDUELLE',
        type: 'classic',
        link: 'https://www.bonduelle.com/fr/',
        img: bonduelle
    },
    {
        name: 'Boucherie Francois',
        type: 'classic',
        link: 'https://www.boucherie-francoisleblant.fr/',
        img: boucherie
    },
    {
        name: 'CENTRE LECLERC BAR SUR AUBE',
        type: 'classic',
        link: "https://www.e.leclerc/mag/e-leclerc-bar-sur-aube",
        img: leclercAube
    },
    {
        name: 'CENTRE LECLERC CHÂTEAU THIERRY',
        type: 'classic',
        link: 'https://www.e.leclerc/mag/e-leclerc-chateau-thierry',
        img: leclercChateau
    },
    {
        name: 'CENTRE LECLERC LAON',
        type: 'classic',
        link: 'https://www.e.leclerc/mag/e-leclerc-chambry',
        img: leclercLaon
    },
    {
        name: 'CENTRE LECLERC LE PLESSIS-BELLEVILLE',
        type: 'classic',
        link: 'https://www.e.leclerc/mag/e-leclerc-plessis-belleville',
        img:leclercplessis
    },
    {
        name: 'CENTRE LECLERC MEAUX',
        type: 'classic',
        link: 'https://www.e.leclerc/mag/e-leclerc-meaux',
        img: leclercmeaaux
    },
    {
        name: 'CENTRE LECLERC PIERRY',
        type: 'classic',
        link: 'https://www.e.leclerc/mag/e-leclerc-pierry',
        img: leclercPierry
    },
    {
        name: 'CENTRE LECLERC VERMELLES',
        type: 'classic',
        link: 'https://www.e.leclerc/mag/e-leclerc-vermelles',
        img: leclercvermelles
    },
    {
        name: 'CENTRE LECLERC VIOLAINES',
        type: 'classic',
        link: 'https://www.e.leclerc/mag/e-leclerc-violaines',
        img:leclercviolaines
    },
    {
        name: 'CENTRE LECLERC VITRY LE FRANÇOIS',
        type: 'classic',
        link: 'https://www.e.leclerc/mag/e-leclerc-violaines',
        img:leclercvitry
    },
    {
        name: 'Champagne Repro',
        type: 'classic',
        link: 'https://www.champagnerepro.fr/',
        img: champagnerepro
    },
    {
        name: 'CM 51',
        type: 'classic',
        link: '',
        img: ''
    },
    {
        name: 'CM Diag 02',
        type: 'classic',
        link: 'https://www.cm-diagnostic-immobilier.fr/',
        img: cmdiag
    },
    {
        name: 'COFRADIF',
        type: 'classic',
        link: '',
        img: ''
    },
    {
        name: 'COLLECO',
        type: 'classic',
        link: 'https://www.colleco.fr/',
        img: colleco
    },
    {
        name: 'Competency Invest',
        type: 'classic',
        link: '',
        img: ''
    },
    {
        name: 'CP SERVICES',
        type: 'classic',
        link: '',
        img: cpservices
    },
    {
        name: 'CUISINELLA',
        type: 'classic',
        link: 'https://www.ma.cuisinella/fr-fr/magasins/aisne/saint-quentin',
        img: cuisinella
    },
    {
        name: 'Dalkia',
        type: 'classic',
        link: 'https://www.dalkia.fr/',
        img: dalkia

    },
    {
        name: 'Daniel Moquet',
        type: 'classic',
        link: 'https://www.daniel-moquet.com/createur-allees/createur_allees_02-saint-quentin.phtml',
        img:danielmoquet

    },
    {
        name: 'DEL ARTE',
        type: 'classic',
        link: 'https://www.delarte.fr/restaurants/1169-saint-quentin',
        img: delarte
    },
    {
        name: 'DECATHLON SAINT-QUENTIN',
        type: 'classic',
        link: 'https://www.decathlon.fr/store-view/magasin-de-sports-saint-quentin-0070045000450',
        img:decatchlon
    },
    {
        name: 'Drain',
        type: 'classic',
        link: 'https://www.entreprise-drain.fr/',
        img:drain
    },
    {
        name: 'EAV',
        type: 'classic',
        link: 'https://espace-alu-vermandois.com/',
        img:eav
    },
    {
        name: 'ECAA',
        type: 'classic',
        link: 'https://www.ecaa-ingenierie.fr/',
        img: ecaa
    },
    {
        name: 'EHTPNGE',
        type: 'classic',
        link: 'https://www.nge.fr/',
        img: nge
    },
    {
        name: 'EIFFAGE ROUTE',
        type: 'classic',
        link: 'https://www.eiffageroute.com/',
        img: eiffageroute
    },
    {
        name: 'ENGIE HOME SERVICES',
        type: 'classic',
        link: 'https://agence.engie-homeservices.fr/20-engie-home-services-saint-quentin',
        img: engiehomeservices
    },
    {
        name: 'ENGIE',
        type: 'classic',
        link: 'https://particuliers.engie.fr/',
        img: engie
    },
    {
        name: 'EOS',
        type: 'classic',
        link: '',
        img: ''
    },
    {
        name: 'EUROP DECORS',
        type: 'classic',
        link: '',
        img: ''
    },
    {
        name: 'GAN ASSURANCES',
        type: 'classic',
        link: 'https://www.agence.gan.fr/859-gan-assurances-saint-quentin-romain-delahaye',
        img: ganassurances
    },
    {
        name: 'GEPELEC LECLERE',
        type: 'classic',
        link: 'https://www.g-leclere.fr/gepelec',
        img: gepelec
    },
    {
        name: 'GESTPAL',
        type: 'classic',
        link: 'https://www.gestpal.com/',
        img: gestpel
    },
    {
        name: 'GF PREMIUM AUTOMOBILES',
        type: 'classic',
        link: 'https://volvocars-concessions.com/fr/saint-quentin',
        img: gfautomobile
    },
    {
        name: 'GM Concept',
        type: 'classic',
        link: 'https://www.concept-gm.fr/',
        img: gmconcept
    },
    {
        name: 'GOREZ TP',
        type: 'classic',
        link: 'https://www.gorez.fr/',
        img: gorez
    },
    {
        name: 'Guidez Frères',
        type: 'classic',
        link: 'https://www.facebook.com/guidezfreres/?locale=fr_FR',
        img: guidez
    },
    {
        name: 'Houtch',
        type: 'classic',
        link: 'https://houtch-plateforme.com/distribution.html',
        img: outch
    },
    {
        name: 'Ibis St Quentin',
        type: 'classic',
        link: 'https://all.accor.com/hotel/1641/index.fr.shtml',
        img: ibis
    },
    {
        name: 'IRON BODY-FIT',
        type: 'classic',
        link: 'https://ironbodyfit.com/nos-studios/saint-quentin/',
        img: ironbody
    },
    {
        name: 'ISOL PICARDIE',
        type: 'classic',
        link: 'https://www.isolpicardie.com/',
        img: isolpicardie
    },
    {
        name: 'Issilens',
        type: 'classic',
        link: 'https://www.issilens.com/',
        img: issilens
    },
    {
        name: 'JEFF DE BRUGES',
        type: 'classic',
        link: 'https://www.jeff-de-bruges.com/jeff-de-bruges-saint-quentin?gclid=CjwKCAjwsvujBhAXEiwA_UXnAJ0NBrrBcJtAKd00B-2y3yiGEYrbGKb2NaDY-7Jtf3og5GyHINpG8hoCEhoQAvD_BwE',
        img: jeffdebruge
    },
    {
        name: 'LAV’ALIM',
        type: 'classic',
        link: 'http://www.lavalim.fr/',
        img: lavalim
    },
    {
        name: 'LE COCTEAU',
        type: 'classic',
        link: 'https://www.facebook.com/people/Restaurant-Le-Cocteau/100064242725402/',
        img: lecocteau
    },
    {
        name: 'LE FROID PICARD',
        type: 'classic',
        link: '',
        img: froidpicard
    },
    {
        name: 'LEDUC PASCAL',
        type: 'classic',
        link: 'https://www.leduc-geometre-expert.com/',
        img: leduc
    },
    {
        name: 'LEICHNAM',
        type: 'classic',
        link: 'https://www.fermetures-leichnam.fr/',
        img: leichman
    },
    {
        name: 'LERICHE TRAVAUX SERVICES',
        type: 'classic',
        link: 'https://www.facebook.com/p/Leriche-Travaux-Services-100077336540955/?paipv=0&eav=AfbDFZ6QsttxigN0VkbuRLR7_40D-4mXT9ZVdijmbH5FsopNhvaLrzs0AXxZ_ZLt20g&_rdr',
        img: leriche
    },
    {
        name: 'LES TOQUES GOURMANDES',
        type: 'classic',
        link: 'https://lestoquesgourmandes.com/',
        img: toquesgourmandes
    },
    {
        name: 'MADE IN FRANCE',
        type: 'classic',
        link: 'https://www.facebook.com/p/Au-Made-In-France-100087789471401/',
        img: madeinfrance
    },
    {
        name: 'MARY AUTOMOBILES',
        type: 'classic',
        link: 'https://www.maryautomobiles.fr/peugeot-saintquentin',
        img: maryauto
    },
    {
        name: 'NISSAN',
        type: 'classic',
        link: 'https://www.gueudet.fr/concessions/nissan/nissan-saint-quentin',
        img: nissan
    },
    {
        name: 'ORPI',
        type: 'classic',
        link: 'https://www.orpi.com/agenceimmocenter/?utm_content=agenceimmocenter',
        img: orpi
    },
    {
        name: 'PMS',
        type: 'classic',
        link: 'https://pmspnsnettoyage.yolasite.com',
        img: pms
    },
    {
        name: 'QUENTIN PLAST',
        type: 'classic',
        link: '',
        img: ''
    },
    {
        name: 'RENOBAT',
        type: 'classic',
        link: 'https://renobat-constructions.com/',
        img: renobat
    },
    {
        name: 'ROHANT SOLAIRE',
        type: 'classic',
        link: 'https://www.rohant-solaire.fr/',
        img: rohant
    },
    {
        name: 'RTA',
        type: 'classic',
        link: 'https://www.rta02.fr/',
        img: rta
    },
    {
        name: 'Signature',
        type: 'classic',
        link: 'https://www.groupe-signature.com/fr',
        img: signature
    },
    {
        name: 'SOGAPEX',
        type: 'classic',
        link: 'https://www.sogapex.fr/',
        img: sogapex
    },
    {
        name: 'SOREVAL HABITAT',
        type: 'classic',
        link: 'https://www.soreval-habitat.fr/',
        img: soreval
    },
    {
        name: 'STI',
        type: 'classic',
        link: 'https://www.societe-tuyauterie-industrielle.fr/',
        img: sti
    },
    {
        name: 'TOP VAN DOOREN',
        type: 'classic',
        link: 'https://www.topvandooren.net/',
        img: topvandooren
    },
    {
        name: 'TRANSDEV',
        type: 'classic',
        link: 'https://www.transdevcompagnieaxonaise.com/',
        img: transdev
    },
    {
        name: 'VEOLIA EAU',
        type: 'classic',
        link: 'https://www.service.eau.veolia.fr/home.html',
        img: veolia
    },
    {
        name: 'Vitamine T',
        type: 'classic',
        link: 'https://www.groupevitaminet.com/structures-insertion/envie-nord/',
        img: vitamine
    },
    {
        name: 'VOLKSWAGEN GUEDET',
        type: 'classic',
        link: 'https://www.gueudet.fr/concessions/volkswagen/volkswagen-saint-quentin',
        img: volksvagen
    },
    {
        name: 'CONVERGENCE CONSULTING S.A.',
        type: 'classic',
        link: 'https://www.facebook.com/ConvergenceConsultingSA/',
        img: convergence
    },
    {
        name: 'MK Energies',
        type: 'classic',
        link: 'https://www.mk-energies.fr/fr/',
        img: mkenegies
    }
]