import React from 'react';
import './PlayerBox.css';
import {Link} from "react-router-dom";
import { BiRightArrow } from "react-icons/bi";
import {Img} from 'react-image';

const PlayerBox = (props) => {
    return (
        <div className="player-box">
            <div className="player-imgBx">
                <Img src={ props.img } alt="image"/>I
            </div>
            <div className={"player_box_name_bx"}>
                <div className={"first_part_pok"}>
                    <span className={"player_box_firstname"}>{ props.firstName }</span>
                    <br/>
                    <span className={"player_box_lastname"}>{ props.lastName }</span>
                </div>
                <span className={"player_box_view_more"} title={`Voir la page de ${props.firstName} ${props.lastName}`}>
                    <Link to={`details/${props.playerId}`}>
                        <BiRightArrow></BiRightArrow>
                    </Link>
                </span>
            </div>

            <span className="blur"></span>
        </div>
    );
};

export default PlayerBox;