import React from 'react';
import HistoryBloc from "../../HistoryBloc/HistoryBloc";
import classes from "./MobileHistoryBlock.module.css";

const MobileHistoryBlock = () => {
    return (
        <section className={classes.home_history_mobile}>
            <h2>Notre histoire</h2>
            <HistoryBloc/>
        </section>
    );
};

export default MobileHistoryBlock;