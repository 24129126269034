import React from 'react';
import classes from "./Shape.module.css";

const Shape = (props) => {
    const style = {
        height: props.height,
        background: `${props.backgroundColor}, url(${props.backgroundImage})`,
        backgroundSize: props.backgroundSize,
        backgroundPosition: props.backgroundPosition,
        backgroundAttachment: props.backgroundAttachment,
        zIndex: 10
    }

    return (
        <div className={classes.shape} style={style}>
            <div className={classes.content}>
                { props.children }
            </div>
        </div>
    );
};

export default Shape;