import React from 'react';
import cdf from "../../../../img/competitions/CDF_logo.png";
import classes from "./CoupeFrance.module.css";
import SqbbPageTitle from "../../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import SqbbShap from "../../../../components/UI/SqbbShap/SqbbShap";
import ratte7 from "../../../../img/pierreRatte/pierre_rate4.jpg";
import {Img} from 'react-image';

const CoupeFrance = () => {
    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(12, 38, 75, 0.6) 0%, rgba(12, 38, 75, 0.8) 45%, rgba(12, 38, 75, 1) 100%)`}
                backgroundImage={ratte7}
                backgroundAttachment={'fixed'}
                backgroundPosition={'center bottom'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Coupe de France"} color={"#fff"}></SqbbPageTitle>
            </SqbbShap>
            <section className={ `${classes.compet_cdp} container-fluid row` }>
                <div className={ `${classes.compet_cdp_img_bx} col-sm-12 col-md-12 col-lg-4` }>
                    <Img src={ cdf } className={classes.cdp_logo} alt="Logo de la coupe de France"/>
                </div>
                <div className={ `${classes.compet_cdp_content} col-sm-12 col-md-12 col-lg-8` }>
                    <h3 className={classes.cdp_title}> La Coupe de France de basket-ball</h3>
                    <p>
                        La Coupe de France de basket-ball est une compétition à élimination directe de basket-ball organisée par la Fédération française de basket-ball.
                        Elle est nommée aussi Trophée Robert Busnel, du nom du basketteur décédé en 1991 Robert Busnel.
                    </p>
                    <p>
                        La Coupe de France de basket-ball Robert Busnel se joue en matches simples, à élimination directe
                        entre les clubs de Betclic Élite, Pro B, NM1 ainsi que le vainqueur,
                        le finaliste et le demi-finaliste ayant perdu contre le vainqueur du Trophée Coupe de France de la saison précédente.
                    </p>
                </div>
            </section>
        </>
    );
};

export default CoupeFrance;