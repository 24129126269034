/*------    Ombre -------*/
import shadow from "../img/players/shadow.png";

/*------    Mathis Dossou-Yovo  -------*/
import doussouProfile from "../img/players/dossou/mathis-removebg-preview.png";
import doussouMP from "../img/players/dossou/Mathis_Dossou-Yovo.webp";

/*------   Malvin Ajinca    -------*/
import ajincaProfile from "../img/players/ajinca/melvin-removebg-preview.png";
import ajincaMP from "../img/players/ajinca/Melvin_Ajinca.webp";

/*------   Lucas Boucaud    -------*/
import boucaudProfile from "../img/players/boucaud/boucaud-removebg-preview.png";
import boucaudMP from "../img/players/boucaud/Lucas_Boucaud.webp";

/*------   William Pfister    -------*/
import williamProfile from "../img/players/pfister/william-removebg-preview.png";
import pfisterMP from "../img/players/pfister/William_Pfister.webp";

/*------   Loic Schwartz    --------*/
import schwartzProfil from "../img/players/schwartz/schwarz-removebg-preview.png"
import schwartzMP from "../img/players/schwartz/Loic_Schwartz.webp";

/*------ Dominik  ---------*/
import dominikProfile from '../img/players/dominik/dominik-removebg-preview.png';

/*-----  James Walker  ------*/
import walkerProfil from '../img/players/walker/walker-removebg-preview.png';

/*-----  Nate Johnson  ------*/
import johnsonProfil from '../img/players/johnson/johnson-removebg-preview.png';


/*----   Will Rayman   -----*/
import raymanProfil from '../img/players/rayman/rayman-removebg-preview.png';

export const playersData = 
[
    {
        id: 1,
        firstName: "Noah",
        lastName: "Kirkwood",
        position: "Arrière",
        number: "22",
        age: 24,
        birth: "27 décembre 1999",
        birthPlace: "Kanata - Canada",
        size: "2.01",
        teamPresence: "2024",
        country: "CANADA",
        profilImg: shadow,
        mainPageImg: shadow,
        smallResume: "Noah Kirkwood est un joueur de basketball né le 27 décembre 1999. Il mesure 2m01 et évolue au poste d’arrière.",
        resume: "Noah Kirkwood commence sa carrière universitaire sous le maillot de l’équipe d'Harvard Crimson. Après 3 saisons complètes en NCAA, l’arrière canadien rallie le Vieux Continent pour commencer sa carrière professionnelle à Bonn, en Allemagne, où il découvre également la Coupe d'Europe avec la BCL.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2024",
                    ending: "-",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Telekom Baskets Bonn",
                    comeIn: "2023",
                    ending: "2024",
                    leagueName: "Basketball-Bundesliga (BBL)"
                },
                {
                    club: "Harvard Crimson",
                    comeIn: "2018",
                    ending: "2022",
                    leagueName: "NCAA"
                }
            ]
        },
        pictures: [
            {
                playerId: [1],
                title: "Action shot",
                tag: ["Match"],
                src: shadow,
                legend: "Noah Kirkwood en action",
                date: "",
                link: "",
                author: "",
                persons: []
            }
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 2,
        firstName: "Giovan",
        lastName: "Oniangue",
        position: "Ailier",
        number: "4",
        age: 33,
        birth: "24 avril 1991",
        birthPlace: "Brazzaville - Congo",
        size: "1.97",
        teamPresence: "2024",
        country: "FRANCE",
        profilImg: shadow,
        mainPageImg: shadow,
        smallResume: "Giovan Oniangue est un joueur de basketball né le 24 avril 1991. Il mesure 1m97 et évolue au poste d’ailier.",
        resume: "Après 7 saisons avec les Metropolitans 92, Giovan a navigué dans plusieurs clubs de première division française. Après trois saisons à Orléans et une à Dijon, il rejoint Saint-Quentin Basket-ball.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2024",
                    ending: "-",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "JDA Dijon",
                    comeIn: "2023",
                    ending: "2024",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Pau-Lacq-Orthez",
                    comeIn: "2021",
                    ending: "2023",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Orléans",
                    comeIn: "2018",
                    ending: "2021",
                    leagueName: "Pro B & Betclic Élite"
                },
                {
                    club: "BCM Gravelines-Dunkerque",
                    comeIn: "2018",
                    ending: "2018",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Boulazac",
                    comeIn: "2017",
                    ending: "2018",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Metropolitans 92",
                    comeIn: "2010",
                    ending: "2017",
                    leagueName: "Betclic Élite"
                }
            ]
        },
        pictures: [
            {
                playerId: [2],
                title: "Training",
                tag: ["Entraînement"],
                src: shadow,
                legend: "Giovan Oniangue à l'entraînement",
                date: "",
                link: "",
                author: "",
                persons: []
            }
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 3,
        firstName: "Marcus",
        lastName: "Santos Silva",
        position: "Pivot",
        number: "14",
        age: 27,
        birth: "7 juin 1997",
        birthPlace: "Boston - USA",
        size: "2.02",
        teamPresence: "2024",
        country: "USA",
        profilImg: shadow,
        mainPageImg: shadow,
        smallResume: "Marcus Santos Silva est un joueur de basketball né le 7 juin 1997. Il mesure 2m02 et évolue au poste de pivot.",
        resume: "Marcus a un parcours unique. Après 4 saisons en NCAA, il a brièvement signé en NFL pour jouer au football américain avant de revenir au basketball en 2023 du côté de Lille.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2024",
                    ending: "-",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Lille",
                    comeIn: "2023",
                    ending: "2024",
                    leagueName: "Pro B"
                },
                {
                    club: "Texas Tech Red Raiders",
                    comeIn: "2020",
                    ending: "2022",
                    leagueName: "NCAA"
                },
                {
                    club: "VCU Rams",
                    comeIn: "2017",
                    ending: "2020",
                    leagueName: "NCAA"
                }
            ]
        },
        pictures: [
            {
                playerId: [3],
                title: "Match highlights",
                tag: ["Match"],
                src: shadow,
                legend: "Marcus Santos Silva en match",
                date: "",
                link: "",
                author: "",
                persons: []
            }
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 4,
        firstName: "Mohamed",
        lastName: "Diakite",
        position: "Ailier / Ailier fort",
        number: "1",
        age: 18,
        birth: "8 avril 2006",
        birthPlace: "Poitiers",
        size: "2.05",
        teamPresence: "2024",
        country: "FRANCE",
        profilImg: shadow,
        mainPageImg: shadow,
        smallResume: "Mohamed Diakite est un joueur de basketball né le 8 avril 2006. Il mesure 2m05 et évolue au poste d’ailier / ailier fort.",
        resume: "Après trois saisons disputées en troisième division française avec le centre de formation de Pôle France, Mohamed rejoint Saint-Quentin Basket-ball pour débuter sa carrière professionnelle.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2024",
                    ending: "-",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Pôle France",
                    comeIn: "2021",
                    ending: "2024",
                    leagueName: "NM1"
                }
            ]
        },
        pictures: [
            {
                playerId: [4],
                title: "Action shot",
                tag: ["Match"],
                src: shadow,
                legend: "Mohamed Diakite lors d'un match",
                date: "",
                link: "",
                author: "",
                persons: []
            }
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 5,
        firstName: "Jerome",
        lastName: "Robinson",
        position: "Arrière / Ailier",
        number: "9",
        age: 27,
        birth: "22 février 1997",
        birthPlace: "Raleigh - USA",
        size: "1.98",
        teamPresence: "2024",
        country: "USA",
        profilImg: shadow,
        mainPageImg: shadow,
        smallResume: "Jerome Robinson est un joueur de basketball né le 22 février 1997. Il mesure 1m99 et évolue au poste d’arrière / ailier.",
        resume: "Jerome fera sa première expérience en dehors des États-Unis cette année sous les ordres de Julien Mahé. Il a joué pour plusieurs équipes NBA, dont les Golden State Warriors.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2024",
                    ending: "-",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Golden State Warriors",
                    comeIn: "2023",
                    ending: "2024",
                    leagueName: "NBA"
                },
                {
                    club: "Santa Cruz Warriors",
                    comeIn: "2021",
                    ending: "2023",
                    leagueName: "NBA G-League"
                },
                {
                    club: "Washington Wizards",
                    comeIn: "2019",
                    ending: "2021",
                    leagueName: "NBA"
                },
                {
                    club: "Los Angeles Clippers",
                    comeIn: "2018",
                    ending: "2020",
                    leagueName: "NBA"
                },
                {
                    club: "Ontario Clippers",
                    comeIn: "2018",
                    ending: "2019",
                    leagueName: "NBA G-League"
                },
                {
                    club: "Boston College Eagles",
                    comeIn: "2015",
                    ending: "2018",
                    leagueName: "NCAA"
                }
            ]
        },
        pictures: [
            {
                playerId: [5],
                title: "Training session",
                tag: ["Entraînement"],
                src: shadow,
                legend: "Jerome Robinson à l'entraînement",
                date: "",
                link: "",
                author: "",
                persons: []
            }
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    },
    {
        id: 6,
        firstName: "Khalid",
        lastName: "Moore",
        position: "Ailier fort",
        number: "12",
        age: 24,
        birth: "29 juillet 2000",
        birthPlace: "Mineola - USA",
        size: "2.01",
        teamPresence: "2024",
        country: "USA",
        profilImg: shadow,
        mainPageImg: shadow,
        smallResume: "Khalid Moore est un joueur de basketball né le 29 juillet 2000. Il mesure 2m01 et évolue au poste d’ailier fort.",
        resume: "Après 5 années en NCAA, Khalid a commencé sa carrière professionnelle en Grèce, attirant ensuite l'attention de Saint-Quentin pour la saison 2024-2025.",
        carrier: {
            pro: [
                {
                    club: "Saint-Quentin BasketBall",
                    comeIn: "2024",
                    ending: "-",
                    leagueName: "Betclic Élite"
                },
                {
                    club: "Lavrio",
                    comeIn: "2023",
                    ending: "2024",
                    leagueName: "A1 Ethniki"
                },
                {
                    club: "Fordham Rams",
                    comeIn: "2022",
                    ending: "2023",
                    leagueName: "NCAA"
                },
                {
                    club: "Georgia Tech Yellow Jackets",
                    comeIn: "2018",
                    ending: "2022",
                    leagueName: "NCAA"
                }
            ]
        },
        pictures: [
            {
                playerId: [6],
                title: "Match action",
                tag: ["Match"],
                src: shadow,
                legend: "Khalid Moore en pleine action lors d'un match",
                date: "",
                link: "",
                author: "",
                persons: []
            }
        ],
        sci: {
            facebook: "",
            instagram: "",
            tweeter: "",
            linkedIn: ""
        }
    }
]

