import React from 'react';
import {NavLink} from "react-router-dom";
import logo from "../../../img/logo.png";
import "./MobileMainNavigation.css";
import {Img} from 'react-image';


const MobileMainNavigation = () => {

    const nav = () => {
        /**
         * Mobile nav toggle
         */
        const mobileNavShow = document.querySelector('.mobile-nav-show');
        const mobileNavHide = document.querySelector('.mobile-nav-hide');

        document.querySelectorAll('.mobile-nav-toggle').forEach(el => {
            el.addEventListener('click', function(event) {
                event.preventDefault();
                mobileNavToogle();
            })
        });

        function mobileNavToogle() {
            document.querySelector('body').classList.toggle('mobile-nav-active');
            mobileNavShow.classList.toggle('d-none');
            mobileNavHide.classList.toggle('d-none');
        }

        /**
         * Toggle mobile nav dropdowns
         */
        const navDropdowns = document.querySelectorAll('.navbar .dropdown > a');

        navDropdowns.forEach(el => {
            el.addEventListener('click', function(event) {
                if (document.querySelector('.mobile-nav-active')) {
                    event.preventDefault();
                    this.classList.toggle('active');
                    this.nextElementSibling.classList.toggle('dropdown-active');

                    let dropDownIndicator = this.querySelector('.dropdown-indicator');
                    dropDownIndicator.classList.toggle('bi-chevron-up');
                    dropDownIndicator.classList.toggle('bi-chevron-down');
                }
            })
        });

        const navLink = document.querySelectorAll(".navLink");
        navLink.forEach(el => {
            el.addEventListener('click', function(event) {
                mobileNavToogle();
            })
        })
    }

    return (
        <>
            <header id="header_mobile" className="header d-flex align-items-center fixed-top">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

                <NavLink to={"/"} className="logo d-flex align-items-center" >
                    <div className={"logo"}>
                        <Img src={logo} alt=""/>
                    </div>
                </NavLink>

                <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
                <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>

                <nav id="navbar" className="navbar">
                    <ul>
                        <li><NavLink to={"/"} className={"navLink"}>Accueil</NavLink></li>
                        <li className="dropdown"><a href="#"><span>Club</span> <i
                            className="bi bi-chevron-down dropdown-indicator"></i></a>
                            <ul>
                                <li className="dropdown"><a href="#"><span>Histoire</span> <i
                                    className="bi bi-chevron-down dropdown-indicator"></i></a>
                                    <ul>
                                        <li><NavLink to={'club/histoire/saint-quentin'} className={"navLink"}>Histoire du club</NavLink></li>
                                        <li><NavLink to={'club/info-pratiques/stade'} className={"navLink"}>Pierre Ratte</NavLink></li>
                                    </ul>
                                </li>
                                <li className="dropdown"><a href="#"><span>Vie du club</span> <i
                                    className="bi bi-chevron-down dropdown-indicator"></i></a>
                                    <ul>
                                        <li><a href={"http://www.sqbb-jsc.fr/"} target={"_blank"} className={"navLink"}>L'association</a></li>
                                    </ul>
                                </li>
                                <li className="dropdown"><a href="#"><span>EN SAVOIR PLUS</span> <i
                                    className="bi bi-chevron-down dropdown-indicator"></i></a>
                                    <ul>
                                       <li><NavLink to={'/contact'} className={"navLink"}>contact</NavLink></li>
                                       <li><NavLink to={'/club/info-pratiques/general'} className={"navLink"}>infos pratiques</NavLink></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li className="dropdown"><a href="#"><span>Saison 24-25</span> <i
                            className="bi bi-chevron-down dropdown-indicator"></i></a>
                            <ul>
                                <li className="dropdown"><a href="#"><span> l'équipe</span> <i
                                    className="bi bi-chevron-down dropdown-indicator"></i></a>
                                    <ul>
                                        <li><NavLink to={'equipe/pro/joueurs'} className={"navLink"}>Joueurs</NavLink></li>
                                        <li><NavLink to={'equipe/pro/staff'} className={"navLink"}>Staff</NavLink></li>
                                    </ul>
                                </li>
                                <li className="dropdown"><a href="#"><span>Compétitions</span> <i
                                    className="bi bi-chevron-down dropdown-indicator"></i></a>
                                    <ul>
                                        <li><NavLink to={'equipe/competitions/betclics'} className={"navLink"}>Betclic Elite</NavLink></li>
                                        <li><NavLink to={'equipe/competitions/cdf'} className={"navLink"}>Coupe de France</NavLink></li>
                                        <li><NavLink to={'equipe/competitions/leaders-cup'} className={"navLink"}>Leaders Cup</NavLink></li>
                                    </ul>
                                </li>
                                <li className="dropdown"><a href="#"><span>Saison 24-25</span> <i
                                    className="bi bi-chevron-down dropdown-indicator"></i></a>
                                    <ul>
                                        <li><NavLink to={'equipe/match/calendrier'} className={"navLink"}>Calendrier et Résultats</NavLink></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li className="dropdown"><a href="#"><span>Partenaires</span> <i className="bi bi-chevron-down dropdown-indicator"></i></a>
                            <ul>
                                <li className="dropdown"><a href="#"><span> Partenaires</span> <i
                                    className="bi bi-chevron-down dropdown-indicator"></i></a>
                                    <ul>
                                        <li><NavLink to={'partenaires/nos-partenaires'} className={"navLink"}>Nos Partenaires</NavLink></li>
                                        <li><NavLink to={'partenaires/devenir-partenaire'} className={"navLink"}>Devenir partenaire</NavLink></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li><NavLink to={'/actualites'} className={"navLink"}>Blog</NavLink></li>
                        <li><NavLink to={'/cashless'} className={"navLink"}>Cashless</NavLink></li>
                    </ul>
                </nav>
            </div>
            </header>
            { setTimeout(() => nav(), 3000) }
        </>
    );
};

export default MobileMainNavigation;