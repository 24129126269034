import React from 'react';
import HomeSlider from "../components/HomeSlider/HomeSlider";
import DiagonalContainer from "../components/UI/DiagonalContainer/DiagonalContainer";
import TicketsContainer from "../components/Ticket/TicketsContainer/TicketsContainer";
import HistoryBloc from "../components/HistoryBloc/HistoryBloc";
import CTAContainer from "../components/CTAContainer/CTAContainer";
import { UAParser } from "ua-parser-js";
import MobileMatchs from "../components/Mobile/Matchs/MobileMatchs";
import MobileHistoryBlock from "../components/Mobile/MobileHistoryBlock/MobileHistoryBlock";
import SqbbTitle from "../components/UI/SqbbTitle/SqbbTitle";
import HomeArticleContainer from "../components/Blog/HomeArticleContainer/HomeArticleContainer";
import {useLoaderData} from "react-router-dom";



const Home = () => {
    const latestPosts = useLoaderData();
    const uaParser = new UAParser();
    console.log('testeur',uaParser.getDevice())
    const matchs = () => {
   
        
            return (
                <DiagonalContainer>
                    <SqbbTitle title={"Les matchs"} color={"#0c264b"}></SqbbTitle>
                    <TicketsContainer></TicketsContainer>
                </DiagonalContainer>
            )
        
    }

    const history = () => {
        if(uaParser.getDevice().type === "mobile"){
            return (
                <MobileHistoryBlock></MobileHistoryBlock>
            )
        }else {
            return (
                <DiagonalContainer>
                    <SqbbTitle title={"Notre histoire"} color={"#0c264b"}></SqbbTitle>
                    <HistoryBloc/>
                </DiagonalContainer>
            )
        }
    }

    return (
        <>
            <HomeSlider
             homeSlider={latestPosts}
            />
            { matchs() }
            <HomeArticleContainer
                posts={latestPosts}
                uaParser={uaParser}
            />
            { history() }
            <CTAContainer></CTAContainer>
        </>
    );
};

export default Home;