import React from 'react';
import "./Error.css";

const Error = () => {
    return (
        <>
            <div className={"error_message_sqbb"}>
                <h1>404 Error Page #2</h1>
                <p className="zoom-area"><b>Oups</b> une erreur est survenu.</p>
                <section className="error-container">
                    <span className="four"><span className="screen-reader-text">4</span></span>
                    <span className="zero"><span className="screen-reader-text">0</span></span>
                    <span className="four"><span className="screen-reader-text">4</span></span>
                </section>
                <div className="link-container">
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/sqbbofficiel" className="more-link">Nous joindre</a>
                </div>
            </div>
        </>
    );
};

export default Error;