import React from 'react';
import champion from "../../../img/palmares/2013.webp";
import SqbbPageTitle from "../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import SqbbShap from "../../../components/UI/SqbbShap/SqbbShap";
import SqbbTitle from "../../../components/UI/SqbbTitle/SqbbTitle";
import whiteeDevils from "../../../img/clubsSupporters/white_devils-1.webp";
import renardsBlancs from "../../../img/clubsSupporters/renard_blancs.webp";
import classes from "./ClubsSupporters.module.css";

const ClubSupporters = () => {
    const whiteDevilsStyle = {
        backgroundImage: `url('${whiteeDevils}')`,
        backgroundPosition: 'center right',
        backgroundSize: "contain",
        marginTop: "0"
    }

    const renardsBlancsStyle = {
        backgroundImage: `url('${renardsBlancs}')`,
        backgroundPosition: 'center right',
        backgroundSize: "50%",
        marginTop: "0"
    }

    const mimillisStyle = {
        backgroundImage: `url('${renardsBlancs}')`,
        backgroundPosition: 'center right',
        backgroundSize: "50%",
        marginTop: "0"
    }

    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(12, 38, 75, 0.6) 0%, rgba(12, 38, 75, 0.8) 45%, rgba(12, 38, 75, 1) 100%)`}
                backgroundImage={ champion }
                backgroundAttachment={'fixed'}
                backgroundPosition={'center center'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Les groupes de supporters"} color={"#fff"}></SqbbPageTitle>
            </SqbbShap>

            <div className={classes.club_supporters_section_title}>
                <SqbbTitle title={"LES WHITE DEVILS"} color={"#fff"}></SqbbTitle>
            </div>
            <section id="hero" className={`hero d-flex align-items-center `} style={whiteDevilsStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4">
                            <p>Président: Pascal Gossart</p>
                            <blockquote data-aos="fade-up" data-aos-delay="100">
                                <p>
                                    Les White Devils sont nés il y a presque 10 ans avec l'idée de créer un second
                                    kop de supporters de l'autre côté du parquet. Donc nous sommes situés en plateau "Charly".
                                    Notre groupe se compose de joyeux drilles qui aiment se rassembler les soirs de matchs
                                    autour de leur passion commune pour le Saint Quentin Basket Ball.
                                </p>
                            </blockquote>
                            <blockquote data-aos="fade-up" data-aos-delay="200">
                                <p>
                                    Vainqueurs ou vaincus, notre fidèle soutien reste indéfectible.
                                    Nous aimons également nous rassembler après certains matchs autour d'un verre
                                    de l'amitié et également lors de déplacement à l'extérieur. L'ambiance à l'intérieur de notre groupe est avant
                                    tout familiale et amicale.
                                </p>
                            </blockquote>
                            <blockquote data-aos="fade-up" data-aos-delay="300">
                                <p>
                                    président des White Devils depuis 5 années et supporter du SQBB depuis 1988.
                                    Très jeune, il est tombé dans le chaudron du palais des sports Pierre De Coubertin
                                    comme il se nommait à l'époque. Il ne l'a plus quitté depuis malgré le parcours
                                    parfois chaotique de son club de cœur.
                                </p>
                            </blockquote>
                            <blockquote data-aos="fade-up" data-aos-delay="400">
                                <p>
                                    D'abord engagé de longues années chez les renards blancs, il a rejoint les White Devils
                                    à leur création voilà une dizaine d'années maintenant. Il se plait à accompagner
                                    cette joyeuse bande de fous furieux supporters des White Devils!
                                    Son envie est désormais de prendre son pied avec le SQBB version Prache/Mahé!
                                </p>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>

            <div className={classes.club_supporters_section_title}>
                <SqbbTitle title={"LES RENARDS BLANCS"} color={"#fff"}></SqbbTitle>
            </div>
            <section id="hero" className={`hero d-flex align-items-center`} style={renardsBlancsStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4">
                            <p>Président: Noel Benoit</p>
                            <blockquote data-aos="fade-up" data-aos-delay="100">
                                <p>
                                    Dans les années 90 "Les Renards Blancs", plusieurs Présidents se sont succédés :
                                    MME CARON Jacqueline, MR TASSART Alain, MR JOURNEAUX Patrick et MR BLEUSE Roger
                                    en place depuis 2013 et adhérent depuis 2001 entouré d'une équipe dynamique.
                                </p>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </section>

            <div className={classes.club_supporters_section_title}>
                <h3 className={classes.club_supporters_slogans}> La grande époque, c'est encore maintenant !</h3>
            </div>
        </>
    );
};

export default ClubSupporters;