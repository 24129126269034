import React from 'react';
import classes from "./HomeArticleCard.module.css";
import DefaultImg from "../../../img/blog/DefaultImg.png";
import { NavLink } from "react-router-dom";
import HtmlRenderer from "../../../core/HtmlRenderer";

const HomeArticleCard = (props) => {
    // Utilisez l'image par défaut si aucune image de fond n'est fournie
    const backgroundImage = props.bgImg ? props.bgImg : DefaultImg;

    const style = {
        backgroundColor: `linear-gradient(rgba(12, 38, 75, 0.4) 0%, rgba(12, 38, 75, 0.6) 35%, rgba(12, 38, 75, 1) 100%)`,
        backgroundImage: backgroundImage,
        backgroundAttachment: '',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    };

    return (
        <div className={`${ classes.sqbb_home_article_card } mt-5 col-md-3`}>
            <div
                className={ classes.sqbb_home_article_card_bx }
                style={{
                    background: `${style.backgroundColor}, url(${style.backgroundImage}) ${style.backgroundRepeat} ${style.backgroundAttachment} ${style.backgroundPosition}`,
                    backgroundSize: style.backgroundSize,
                }}
            >
                <div className={ classes.sqbb_home_article_card_footer }>
                    <h4><HtmlRenderer content={ props.title }></HtmlRenderer></h4>
                    <NavLink to={ props.articleLink } className={ classes.sqbb_home_article_card_read_btn }>
                        Lire l'article
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default HomeArticleCard;
