import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import Root from "./pages/Root";
import Home from "./pages/Home";
import History from "./pages/club/history/History";
import Partners from "./pages/partners/partners/Partners";
import Contact from "./pages/Contact";
import Players from "./pages/team/proteam/Players";
import PlayerDetail from "./pages/team/proteam/PlayerDetail";
import Palmares from "./pages/club/history/Palmares";
import Organisation from "./pages/club/history/Organisation";
import Legends from "./pages/club/history/Legends";
import CultPhotos from "./pages/club/history/CultPhotos";
import Shop from "./pages/club/access/Shop";
import HeadQuarter from "./pages/club/access/HeadQuarter";
import Parquet from "./pages/club/access/Parquet";
import Accreditation from "./pages/club/access/Accreditation";
import Staff from "./pages/team/proteam/Staff";
import BetclickElite from "./pages/team/competitions/Betclic/BetclickElite";
import MatchsSchedules from "./pages/team/matchs/MatchsSchedules";
import Results from "./pages/team/matchs/Results";
import BecomePartners from "./pages/partners/partners/BecomePartners";
import PartnersEvent from "./pages/partners/partners/PartnersEvent";
import MatchGuide from "./pages/partners/praticals/MatchGuide";
import ParquetAccess from "./pages/partners/praticals/ParquetAccess";
import "aos/dist/aos.css";
import CoupeFrance from "./pages/team/competitions/CoupeFrance/CoupeFrance";
import LeadersCup from "./pages/team/competitions/LeadersCup/LeadersCup";
import ClubSupporters from "./pages/team/proteam/ClubSupporters";
import Benevoles from "./pages/club/life/Benevoles";
import Article from "./pages/blog/article/Article";
import Archive from "./pages/blog/archive/Archive";
import findAllPost from "./loaders/posts/findAllPosts";
import findOnePostById from "./loaders/posts/findOnePostById";
import findLatestPosts from "./loaders/posts/findLatestPosts";
import Category from "./pages/blog/category/Category";
import findCategory from "./loaders/posts/findCategory";
import Tag from "./pages/blog/tag/Tag";
import findTag from "./loaders/posts/findTag";
import Error from "./components/Error/Error";
import React from "react";
import Cashless from "./pages/cashless/Cashless";
import GeneralFaq from "./components/Club/General/GeneralFaq";

const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />,
        errorElement: <Error />,
        loader: findLatestPosts,
      },
      {
        path: "club",
        errorElement: <Error />,
        children: [
          {
            path: "histoire",
            errorElement: <Error />,
            children: [
              {
                path: "saint-quentin",
                errorElement: <Error />,
                element: <History />,
              },
              {
                path: "organisation",
                errorElement: <Error />,
                element: <Organisation />,
              },
              {
                path: "palmares",
                errorElement: <Error />,
                element: <Palmares />,
              },
              {
                path: "clubs-supportaires",
                errorElement: <Error />,
                element: <ClubSupporters />,
              },
            ],
          },
          {
            path: "vie-du-club",
            children: [
              {
                path: "benevoles",
                errorElement: <Error />,
                element: <Benevoles />,
              },
              {
                path: "groupes-supporters",
                errorElement: <Error />,
                element: <ClubSupporters />,
              },
            ],
          },
          {
            path: "photos",
            children: [
              {
                path: "cultes",
                errorElement: <Error />,
                element: <CultPhotos />,
              },
              {
                path: "legendes",
                errorElement: <Error />,
                element: <Legends />,
              },
            ],
          },
          {
            path: "info-pratiques",
            children: [
              {
                path: "general",
                errorElement: <Error />,
                element: <GeneralFaq />,
              },
              {
                path: "boutique",
                errorElement: <Error />,
                element: <Shop />,
              },
              {
                path: "siege",
                errorElement: <Error />,
                element: <HeadQuarter />,
              },
              {
                path: "stade",
                errorElement: <Error />,
                element: <Parquet />,
              },
              {
                path: "accreditation",
                errorElement: <Error />,
                element: <Accreditation />,
              },
            ],
          },
        ],
      },
      {
        path: "equipe",
        errorElement: <Error />,
        children: [
          {
            path: "pro",
            errorElement: <Error />,
            children: [
              {
                path: "joueurs",
                errorElement: <Error />,
                element: <Players />,
              },
              {
                path: "joueurs/detail",
                errorElement: <Error />,
                element: <PlayerDetail />,
              },
              {
                path: "staff",
                errorElement: <Error />,
                element: <Staff />,
              },
            ],
          },
          {
            path: "competitions",
            errorElement: <Error />,
            children: [
              {
                path: "betclics",
                errorElement: <Error />,
                element: <BetclickElite />,
              },
              {
                path: "cdf",
                errorElement: <Error />,
                element: <CoupeFrance />,
              },
              {
                path: "leaders-cup",
                errorElement: <Error />,
                element: <LeadersCup />,
              },
            ],
          },
          {
            path: "match",
            errorElement: <Error />,
            children: [
              {
                path: "calendrier",
                errorElement: <Error />,
                element: <MatchsSchedules />,
              },
              {
                path: "resultats",
                errorElement: <Error />,
                element: <Results />,
              },
            ],
          },
        ],
      },
      {
        path: "partenaires",
        errorElement: <Error />,
        children: [
          {
            path: "nos-partenaires",
            errorElement: <Error />,
            element: <Partners />,
          },
          {
            path: "devenir-partenaire",
            errorElement: <Error />,
            element: <BecomePartners />,
          },
          {
            path: "evenement",
            errorElement: <Error />,
            element: <PartnersEvent />,
          },
        ],
      },
      {
        path: "guides",
        errorElement: <Error />,
        children: [
          {
            path: "match",
            errorElement: <Error />,
            element: <MatchGuide />,
          },
          {
            path: "acces-stade",
            errorElement: <Error />,
            element: <ParquetAccess />,
          },
        ],
      },
      {
        path: "contact",
        errorElement: <Error />,
        element: <Contact />,
      },
      {
        path: "",
        errorElement: <Error />,
        children: [
          {
            path: "actualites",
            element: <Archive />,
            errorElement: <Error />,
            loader: findAllPost,
          },
          {
            path: "actualites/article/:id",
            errorElement: <Error />,
            element: <Article />,
            loader: ({ params }) => findOnePostById(params.id),
          },
          {
            path: "actualites/category/:id",
            errorElement: <Error />,
            element: <Category />,
            loader: ({ params }) => findCategory(params.id),
          },
          {
            path: "actualites/tag/:id",
            errorElement: <Error />,
            element: <Tag />,
            loader: ({ params }) => findTag(params.id),
          },
        ],
      },
      {
        path: "cashless",
        errorElement: <Error />,
        element: <Cashless />,
      },
    ],
  },
  {
    path: "equipe/pro/joueurs/details/:id",
    errorElement: <Error />,
    element: <PlayerDetail />,
  },
]);

function App() {
  return (
   // <HelmetProvider>
      <RouterProvider router={router} />
  //  </HelmetProvider>
  );
}

export default App;
