import React, {useEffect, useState} from 'react';
import PartnersContainer from "../../../components/Partners/PartnersContainer/PartnersContainer";
import { partnersData } from "../../../datas/partnersData";
import classes from "./Partners.module.css";
import SqbbTitle from "../../../components/UI/SqbbTitle/SqbbTitle";
import SqbbPageTitle from "../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import ratte7 from "../../../img/pierreRatte/pierre_rate4.jpg";
import SqbbShap from "../../../components/UI/SqbbShap/SqbbShap";
import PremiumPartnersContainer from "../../../components/Partners/PremiumPartnersContainer/PremiumPartnersContainer";

const Partners = () => {
    const [ partners, setPartners] = useState(partnersData);
    const [ classicPartners, setClassicPartners ] = useState('');
    const [ officialPartners, setOfficialPartners ] = useState('');
    const [ providerPartners, setProviderPartners ] = useState('');
    const [ publicPartners, setPublicPartners ] = useState('');
    const [ majorPartners, setMajorParters ] = useState('');

    useEffect(() => {
        setClassicPartners( _ => partners.filter(partner => partner.type === 'classic').sort((a, b) => a.name.localeCompare(b.name)));
        setOfficialPartners( _ => partners.filter(partner => partner.type === 'official').sort((a,b) => a.name.localeCompare(b.name)));
        setProviderPartners( _ => partners.filter(partner => partner.type === 'provider').sort((a,b) => a.name.localeCompare(b.name)));
        setPublicPartners( _ => partners.filter(partner => partner.type === 'public').sort((a,b) => a.name.localeCompare(b.name)));
        setMajorParters(_ => partners.filter(partner => partner.type === 'major').sort((a,b) => a.name.localeCompare(b.name)));

    }, [ partners ])

    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.4) 45%, rgba(255, 255, 255, 1) 100%)`}
                backgroundImage={ratte7}
                backgroundAttachment={'fixed'}
                backgroundPosition={'center center'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Nos partenaires"} color={"#0c264b"}></SqbbPageTitle>
            </SqbbShap>

            {
                majorPartners.length > 0 &&
                <section className={classes.partner__section} style={{marginTop: '-8em', paddingTop: '12em'}}>
                    <div className={ classes.partner__section__title }>
                        <SqbbTitle title={"Partenaires premium"} color={"#0c264b"}></SqbbTitle>
                    </div>
                    <PremiumPartnersContainer
                        partners={ majorPartners }
                    />
                </section>
            }

            {
                publicPartners.length > 0 &&
                <section className={classes.partner__section}>
                    <div className={ classes.partner__section__title }>
                        <SqbbTitle title={"Partenaires publics"} color={"#0c264b"}></SqbbTitle>
                    </div>
                    <PartnersContainer
                        partners={ publicPartners }
                    />
                </section>
            }

            {
                officialPartners.length > 0 &&
                <section className={ classes.partner__section }>
                    <div className={ classes.partner__section__title }>
                        <SqbbTitle title={"Partenaires officiels"} color={"#0c264b"}></SqbbTitle>
                    </div>
                    <PartnersContainer
                        partners={ officialPartners }
                    />
                </section>
            }
            {
                providerPartners.length > 0 &&
                <section className={ classes.partner__section }>
                    <div className={ classes.partner__section__title }>
                        <SqbbTitle title={"Fournisseurs"} color={"#0c264b"}></SqbbTitle>
                    </div>
                    <PartnersContainer
                        partners={ providerPartners }
                    />
                </section>
            }
            {
                classicPartners.length > 0 &&
                <section className={classes.partner__section} style={{marginTop: '-8em', paddingTop: '12em'}}>
                    <div className={ classes.partner__section__title }>
                        <SqbbTitle title={"Partenaires"} color={"#0c264b"}></SqbbTitle>
                    </div>
                    <PartnersContainer
                        partners={ classicPartners }
                    />
                </section>
            }
        </>
    );
};

export default Partners;