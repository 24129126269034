import React from 'react';
import classes from "./PremiumPartnersContainer.module.css";
import PartnerCard from "../PartnerCard/PartnerCard";
import PremiumPartnersCard from "../PremiumPartnersCard/PremiumPartnersCard";

const PremiumPartnersContainer = (props) => {
    return (
        <div className={ classes.premium_partners__container }>
            {
                props.partners && props.partners.map((partner, i) => {
                    return (
                        <PremiumPartnersCard
                            partner={ partner }
                            key={i}
                        />
                    )
                })
            }
        </div>
    );
};

export default PremiumPartnersContainer;