import React, { useEffect, useState } from "react";
import "./Article.css";
import DefaultImg  from "../../../img/blog/DefaultImg.png";
import BlogSideBar from "../../../components/Blog/BlogSideBar/BlogSideBar";
import PostAuthor from "../../../components/Blog/PostAuthor/PostAuthor";
import ArticleCore from "../../../components/Blog/ArticleCore/ArticleCore";
import { useLoaderData, useLocation } from "react-router-dom";
import HandleArticleCoverImg from "../../../core/HandleArticleCoverImg";
import findLatestPosts from "../../../loaders/posts/findLatestPosts";
import { Helmet } from "react-helmet";

const Article = () => {
  const data = useLoaderData();
  const [post, setPost] = useState(data);

  const termCategory = post._embedded["wp:term"][0].map((category) => {
    return {
      id: category.id,
      slug: category.slug,
    };
  });

  const termTag = post._embedded["wp:term"][1].map((tag) => {
    return {
      id: tag.id,
      slug: tag.slug,
    };
  });

  const mainImg = HandleArticleCoverImg(
    post._embedded["wp:featuredmedia"],
    "attachment",
    "full"
  );

  console.log("postimage", mainImg);

  const author = post._embedded.author[0].name;
  const date = post.date;
  const pageTitle = "SQBB | " + post.title.rendered;
  const pageDescription = "Votre description ici"; // Vous pouvez extraire une description courte de post.content.rendered ou d'une autre source

  return (
    <>
      <section
        id="blog"
        className="blog"
        style={{ marginTop: "10em", marginBottom: "5em", textAlign: "left" }}
      >
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDescription} />
          <meta property="og:title" content={pageTitle} />
          <meta property="og:description" content={pageDescription} />
          <meta property="og:image" content={mainImg} />
          <meta property="og:type" content="article" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDescription} />
          <meta name="twitter:image" content={mainImg} />
        </Helmet>

        <div className="container" data-aos="fade-up">
          <div className="row g-5">
            <div className="col-lg-8">
              <ArticleCore
                mainImg={mainImg}
                title={post.title.rendered}
                author={author}
                date={date}
                renderedContent={post.content.rendered}
                termCategory={termCategory}
                termTag={termTag}
              />
              <PostAuthor />
            </div>

            <div className="col-lg-4">
              <BlogSideBar termCategory={termCategory} termTag={termTag} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Article;
