import React from 'react';
import classes from "./CashlessForm.module.css";

const CashlessForm = () => {
    return (
        <div className={`container`}>
            <div className={`row col-12`}>
                <div className={classes.cashless_form_bx}>
                    <iframe style={{height: "100%", width: "100%"}} src="https://widget.weezevent.com/pay/446841/widgets/e582ddb5-15dd-4782-8971-35409b4dc024/login" ></iframe>
                </div>
            </div>
        </div>
    );
};

export default CashlessForm;