import React, {useEffect, useState} from 'react';
import Shape from "../../../components/UI/Shap/Shape";
import {historyData} from "../../../datas/history";
import ErasContainer from "../../../components/Eras/EraContainer/ErasContainer";
import history from '../../../img/history/history_slider.webp';
import SqbbPageTitle from "../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import SqbbShap from "../../../components/UI/SqbbShap/SqbbShap";

const History = () => {
    const [eras, setEras] = useState(null);

    useEffect(() => {
        setEras( _ => historyData.eras );
    },[eras]);

    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(12, 38, 75, 0.6) 0%, rgba(12, 38, 75, 0.8) 45%, rgba(12, 38, 75, 1) 100%)`}                backgroundImage={history}
                backgroundAttachment={'fixed'}
                backgroundPosition={'center top'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Histoire du club"} color={"#fff"}></SqbbPageTitle>
            </SqbbShap>
            <ErasContainer eras={ historyData.eras }/>
        </>
    )
};

export default History;