import React from 'react';
import ErasContainer from "../../../components/Eras/EraContainer/ErasContainer";
import {palmaresData} from "../../../datas/palmares";
import SqbbPageTitle from "../../../components/UI/SqbbPageTitle/SqbbPageTitle";
import SqbbShap from "../../../components/UI/SqbbShap/SqbbShap";
import champion from "../../../img/palmares/2013.webp";

const Palmares = () => {
    return (
        <>
            <SqbbShap
                height={'50vh'}
                backgroundColor={`linear-gradient(rgba(12, 38, 75, 0.6) 0%, rgba(12, 38, 75, 0.8) 45%, rgba(12, 38, 75, 1) 100%)`}
                backgroundImage={ champion }
                backgroundAttachment={'fixed'}
                backgroundPosition={'center center'}
                backgroundRepeat={'no-repeat'}
                backgroundSize={'cover'}
            >
                <SqbbPageTitle title={"Notre Palmares"} color={"#fff"}></SqbbPageTitle>
            </SqbbShap>
            <ErasContainer eras={ palmaresData.eras }/>
        </>
    );
};

export default Palmares;