import React, {useState} from 'react';
import {Swiper, SwiperSlide, useSwiper, useSwiperSlide} from 'swiper/react';
import {Autoplay, EffectCoverflow, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-coverflow'
import TicketCard from "../TicketCard/TicketCard";
import './TicketsContainer.css';
import { Link } from "react-router-dom";
import {matchsData} from "../../../datas/matchs";
import {teamDatas} from "../../../datas/teams";

const TicketsContainer = (props) => {

    const [ sqbb, setSqbb ] = useState(teamDatas[0])
    const computeMatchs = () => {
        return matchsData
            .map(match => {
                const adversaire = teamDatas.find(team => team.id === match.opponentId);
                return {
                    id: match.id,
                    date: match.date,
                    hours: null,
                    month: match.month,
                    fullDate: match.fullDate,
                    competition: match.competition,
                    domicileTeamName: match.isHome ? sqbb.name : adversaire.name,
                    domicileTeamLogo: match.isHome ? sqbb.logo : adversaire.logo,
                    externTeamName: match.isHome ? adversaire.name : sqbb.name,
                    externTeamLogo: match.isHome ? adversaire.logo : sqbb.logo,
                    place: match.isHome ? sqbb.salle : adversaire.salle,
                    isHome: false,
                }
            })
            .filter(match => +new Date(match.fullDate) > Date.now());
    }
    const [ matchs, setMatchs] = useState(computeMatchs);

    const swiperParams = {
        className: 'swiper-container',
        wrapperClass: 'swiper-wrapper',
        slideClass: 'swiper-slide',
        modules:[Autoplay, Pagination, Navigation, EffectCoverflow],
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "4",
        effect: "coverflow",
        coverflowEffect: {
            rotate: 30,
            stretch: 1,
            depth: 200,
            modifier: 1,
            slideShadows: true,
        },
        pagination: {
            el: ".swiper-pagination",
        },
    }

    return (
        <>
            <section id='matchs'>
                <Swiper navigation={true} { ...swiperParams }>
                    {
                        matchs.map( match => {
                            return (
                                <SwiperSlide key={match.id}>
                                    <TicketCard { ...match }></TicketCard>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>

                <div className="d-flex align-items-center match_bloc_btn_bx">
                    <Link to={'/equipe/match/calendrier'} className="glightbox match_bloc_btn">Voir le calendrier</Link>
                </div>
            </section>

        </>
    );
};

export default TicketsContainer;