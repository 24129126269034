import React, {useEffect, useRef, useState} from 'react';
import Isotope from 'isotope-layout';
import './PlayerPhotos.css';
import { BiPlus, BiLink } from "react-icons/bi";
import {Img} from 'react-image';


const PlayerPhotos = () => {

    const [isotope, setIsotope] = useState(null);
    const [filterKey, setFilterKey] = useState('*');
    const isotopeContainer = useRef(null);

    useEffect(() => {
        setIsotope(
            new Isotope('.portfolio-container', {
                itemSelector: '.portfolio-item', // filter-item: className of the isotope elements
                layoutMode: 'fitRows', // for horizontal isotope
            })
        );
    }, []);

    useEffect(() => {
        if (isotope) {
            // sanity check
            filterKey === '*'
                ? isotope.arrange({ filter: `*` })
                : isotope.arrange({ filter: `.${filterKey}` });
        }
    }, [isotope, filterKey]);

    return (
        <div className="container">

            <div className="section-title">
                <h2>Portfolio</h2>
                <p>Saint-Quentin</p>
            </div>

            <div className="row">
                <div className="col-lg-12 d-flex justify-content-center">
                    <ul id="portfolio-flters">
                        <li onClick={() => setFilterKey('*')} className="filter-active">TOUTES</li>
                        <li onClick={() => setFilterKey('filter-app')}>équipe DE FRANCE</li>
                        <li onClick={() => setFilterKey('filter-champion')}>Champion</li>
                    </ul>
                </div>
            </div>

            <div className="row portfolio-container">

                <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                    <div className="portfolio-wrap">
                        <Img src="https://basketactu.com/wp-content/uploads/2023/06/Mathis-Dossou-Yovo.jpeg" className="img-fluid"
                             alt=""/>
                        <div className="portfolio-info">
                            <h4>Sélection en équipe de france</h4>
                            <p>France</p>
                            <div className="portfolio-links">
                                <a href="https://basketactu.com/wp-content/uploads/2023/06/Mathis-Dossou-Yovo.jpeg"
                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                   title="Crédit photo">
                                    <BiPlus></BiPlus>
                                </a>
                                <a href="portfolio-details.html" data-gallery="portfolioDetailsGallery"
                                   data-glightbox="type: external" className="portfolio-details-lightbox"
                                   title="Portfolio Details">
                                    <BiLink></BiLink>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-champion">
                    <div className="portfolio-wrap">
                        <Img src="https://basketactu.com/wp-content/uploads/2023/06/Mathis-Dossou-Yovo.jpeg" className="img-fluid"
                             alt=""/>
                        <div className="portfolio-info">
                            <h4>Champion Pro B</h4>
                            <p>Champion Pro B</p>
                            <div className="portfolio-links">
                                <a href="https://basketactu.com/wp-content/uploads/2023/06/Mathis-Dossou-Yovo.jpeg"
                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                   title="Crédit photo">
                                    <BiPlus></BiPlus>
                                </a>
                                <a href="portfolio-details.html" data-gallery="portfolioDetailsGallery"
                                   data-glightbox="type: external" className="portfolio-details-lightbox"
                                   title="Portfolio Details">
                                    <BiLink></BiLink>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-champion">
                    <div className="portfolio-wrap">
                        <Img src="https://basketactu.com/wp-content/uploads/2023/06/Mathis-Dossou-Yovo.jpeg" className="img-fluid"
                             alt=""/>
                        <div className="portfolio-info">
                            <h4>Champion Pro B</h4>
                            <p>Champion Pro B</p>
                            <div className="portfolio-links">
                                <a href="https://basketactu.com/wp-content/uploads/2023/06/Mathis-Dossou-Yovo.jpeg"
                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                   title="Crédit photo">
                                    <BiPlus></BiPlus>
                                </a>
                                <a href="portfolio-details.html" data-gallery="portfolioDetailsGallery"
                                   data-glightbox="type: external" className="portfolio-details-lightbox"
                                   title="Portfolio Details">
                                    <BiLink></BiLink>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-champion">
                    <div className="portfolio-wrap">
                        <Img src="https://basketactu.com/wp-content/uploads/2023/06/Mathis-Dossou-Yovo.jpeg" className="img-fluid"
                             alt=""/>
                        <div className="portfolio-info">
                            <h4>Champion Pro B</h4>
                            <p>Champion Pro B</p>
                            <div className="portfolio-links">
                                <a href="https://basketactu.com/wp-content/uploads/2023/06/Mathis-Dossou-Yovo.jpeg"
                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                   title="Crédit photo">
                                    <BiPlus></BiPlus>
                                </a>
                                <a href="portfolio-details.html" data-gallery="portfolioDetailsGallery"
                                   data-glightbox="type: external" className="portfolio-details-lightbox"
                                   title="Portfolio Details">
                                    <BiLink></BiLink>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 portfolio-item filter-champion">
                    <div className="portfolio-wrap">
                        <Img src="https://basketactu.com/wp-content/uploads/2023/06/Mathis-Dossou-Yovo.jpeg" className="img-fluid"
                             alt=""/>
                        <div className="portfolio-info">
                            <h4>Champion Pro B</h4>
                            <p>Champions </p>
                            <div className="portfolio-links">
                                <a href="https://basketactu.com/wp-content/uploads/2023/06/Mathis-Dossou-Yovo.jpeg"
                                   data-gallery="portfolioGallery" className="portfolio-lightbox"
                                   title="Crédit photo">
                                    <BiPlus></BiPlus>
                                </a>
                                <a href="portfolio-details.html" data-gallery="portfolioDetailsGallery"
                                   data-glightbox="type: external" className="portfolio-details-lightbox"
                                   title="Portfolio Details">
                                    <BiLink></BiLink>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default PlayerPhotos;